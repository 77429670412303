const contenerReducer = {
    home_layout: {
        content_loading: false,
        indexchart: {},
        trade_statistics: {
            today: {},
            tenday: [],
            tendaychart: []
        }
    },

    contactus_layout: {
        content_loading: false,
        content: {}
    },

    common_content: {
        content_loading: false,
        content: {}
    },
    content_loading: true,
    search_content: [],
    search_key: '',
    hightlight_news:[],
    hightlight_news_category:[],
    news_category_name:[],
    company_trade:{},
    historical_data:[],
    company_profile:{},
    watchlist:[],
    companyStatistics:{},
    company_share_holding:{},
    company_financial:{}
}

export default (state = contenerReducer, action) => {
    switch (action.type) {

        case 'SET_SEARCH_KEY':

            return {
                ...state,
                search_key: action.search,
            }
        case 'INIT_SEARCH_CONTENT':
            return {
                ...state,
                search_content: action.data,
            }

        case 'ADD_SEARCH_CONTENT':
            return {
                ...state,
                search_content: [...state.search_content, ...action.data],
            }
        case 'ADDED_CONTENT':
            var searchobj = 'page_' + action.id;
            var contentdata = state;

            Object.entries(state).map(layout => {

                if (layout[0] == searchobj) {

                    contentdata[searchobj] = { content: action.data, content_loaded: true }
                }
            });

            return {
                ...state,
                ...contentdata,
                content_loading: false,
            }
        case 'CONTENT_LOAD_STATUS':
            var searchobj = 'page_' + action.id;
            var contentdata = state;

            Object.entries(state).map(layout => {
                if (layout[0] == searchobj) {
                    contentdata[searchobj] = { content: contentdata[searchobj].content, content_loaded: true }
                }
            });

            return {
                ...state,
                ...contentdata,
                content_loading: false,
            }

        case 'INIT_HOME_LAYOUT':

            return {
                ...state,
                home_layout: {
                    ...state.home_layout,
                    content_loading: true,
                    content: action.data,
                }
            }
        case 'INIT_TRADE_STATISTICS_TODAY':
            return {
                ...state,
                home_layout: {
                    ...state.home_layout,
                    trade_statistics: {
                        ...state.home_layout.trade_statistics,
                        today: action.data
                    }
                }
            }
        case 'INIT_HIGHLIGHT_NEWS':
            // console.log('INIT_hightlight_news',action)
            return {
                ...state,
                hightlight_news: action.data
            }
        case 'INIT_HIGHLIGHT_NEWS_CATEGORY':
            // console.log('INIT_hightlight_news',action)
            return {
                ...state,
                hightlight_news_category: action.data
            }
        case 'INIT_NEWS_CATEGORY_NAME':
            // console.log('INIT_hightlight_news',action)
            return {
                ...state,
                news_category_name: action.data
            }
        case 'INIT_COMPANY_TRADE':
            // console.log("Yes action =",action.data);
            return {
                ...state,
                company_trade: action.data
            }
        case 'INIT_HISTORICAL_DATA':
           
            return {
                ...state,
                historical_data: action.data
            }
        case 'ADD_HISTORICAL_DATA':
           
            return {
                ...state,
                historical_data:[...state.historical_data,... action.data]
            }
        case 'INIT_COMPANY_PROFILE':
           
            return {
                ...state,
                company_profile: action.data
            }
        case 'INIT_WATCHLIST':
           
            return {
                ...state,
                watchlist: action.data
            }
        case 'INIT_COMPANY_STATISTICS':
           
            return {
                ...state,
                companyStatistics: action.data
            }
        case 'INIT_COMPANY_SHARE_HOLDING':
           
            return {
                ...state,
                company_share_holding: action.data
            }
        case 'INIT_COMPANY_FINANCIALS':
           
            return {
                ...state,
                company_financial: action.data
            }
        

        default:
            return state;

    }
}