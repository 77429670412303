import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { connect } from "react-redux";
import { setLayout, setPath, setPathLayout } from "../../actions/Utils";
import { highlightNews, highlightNewsCategory } from "../../actions/HomeLayout";
import { image_url } from "../../config/Api";
import DatetimeAgo from "../common/DatetimeAgo";

class HighlightNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hightlight_news: [],
      hightlight_news_category: [],
    };
  }
  componentDidMount = () => {
    this.props.highlightNews({}).then((res) => {
      this.setState({
        hightlight_news: res.data,
      });
    });
    this.props.highlightNewsCategory({}).then((res) => {
      this.setState({
        hightlight_news_category: res.data,
      });
    });
  };
  static getDerivedStateFromProps(props, state) {
    return {
      hightlight_news: props.hightlight_news,
      hightlight_news_category: props.hightlight_news_category,
    };
  }

  render() {
    var section_content = this.state.hightlight_news;
    // console.log("section_content =",section_content);
    var first_news = section_content.length ? section_content[0] : {};
    var category_news = this.state.hightlight_news_category;
    console.log(
      "first_news =",
      first_news,
      " section_content =",
      section_content
    );
    return (
      <Fragment>
        <div className="news-highlight">
          {section_content && section_content.length
            ? Object.keys(first_news).length && (
                <div className="long-news">
                  <div className="image">
                    {first_news.file && (
                      <img
                        src={`${image_url}` +'uploads/news/large/'+ first_news.file}
                        alt="image"
                        className="img-responsive"
                      />
                    )}
                    {!first_news.file && (
                      <img
                        src={`${image_url}uploads/no-image/no-image.png`}
                        alt="image"
                        className="img-responsive"
                      />
                    )}
                  </div>
                  <div className="first-blog-content">
                  <h3>{first_news.title && <Link to={{ pathname: `${first_news.slug ? '/portal_news/' + first_news.slug: ''}` }}  className="news-link" style={{textDecoration:"none"}}>{first_news.title}</Link>}</h3>
                    {/* <h3 className="blog-title">{first_news.title}</h3> */}
                    <p>{first_news.short_description}</p>
                    <span>{first_news.source}</span>
                  </div>
                </div>
              )
            : ""}
          <div className="news-blog">
            {section_content &&
              section_content.map((row, key) => {
                return key !== 0 ? (
                  <div className="blog-row" key={key}>
                    <div className="image">
                      {row.file && (
                        <img
                          src={`${image_url}` +'uploads/news/thumbs/'+ row.file}
                          alt="image"
                          className="img-responsive"
                        />
                      )}
                      {!row.file && (
                        <img
                          src={`${image_url}uploads/no-image/no-image.png`}
                          alt="image"
                          className="img-responsive"
                        />
                      )}
                    </div>
                    <div className="title">
                      {" "}
                      <h3>
                        {row.title && (
                          <Link
                            to={{
                              pathname: `${
                                row.slug ? "/portal_news/" + row.slug : ""
                              }`,
                            }}
                            className="news-link" style={{textDecoration:"none"}}
                          >
                            {row.title}
                          </Link>
                        )}
                      </h3>
                      <span>{row.source}</span>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
          </div>
        </div>

        <div className="news-category">
          {category_news &&
            category_news.map((row, key) => {
              return (
                <div className="news-row" key={key}>
                  <div className="image">
                    {row.file && (
                      <img
                        src={`${image_url}` +'uploads/news/thumbs/'+ row.file}
                        alt="image"
                        height={"132px"}
                        width={"282px"}
                      />
                    )}
                    {!row.file && (
                      <img
                        src={`${image_url}uploads/no-image/no-image.png`}
                        alt="image"
                      />
                    )}
                  </div>
                  <div className="news-description">
                    <div className="news-category">
                      <p>
                        <span className="catgegory">{row.category_name}</span>
                        <i className="fa fa-chevron-double-right"></i>
                        <span className="source">{row.source}</span>
                        <i className="fa fa-chevron-double-right"></i>
                        <span className="datetime">
                          <DatetimeAgo
                            date={row.published_date}
                            locale="en-US"
                            timeStyle="twitter"
                          />
                        </span>
                      </p>
                    </div>
                    <div className="news-title">
                      <h5>
                        {row.title && (
                          <Link
                            to={{
                              pathname: `${
                                row.slug ? "/portal_news/" + row.slug : ""
                              }`,
                            }}
                            className="news-link" style={{textDecoration:"none"}}
                          >
                            {row.title}
                          </Link>
                        )}
                      </h5>
                    </div>
                    <div className="news-details">
                      <p>{row.short_description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hightlight_news: state.contents.hightlight_news,
  hightlight_news_category: state.contents.hightlight_news_category,
  setting: state.utils.setting,
});

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  highlightNews: (requestData) => dispatch(highlightNews(requestData)),
  highlightNewsCategory: (requestData) =>
    dispatch(highlightNewsCategory(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlightNews);
