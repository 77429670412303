import $ from "jquery";
import Cookies from "js-cookie";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  setLayout,
  setPath,
  setPathLayout,
  setSearchKey,
  getSetting,
} from "../../../actions/Utils";
import { setLoginStatus, WatchlistShow } from "../../../actions/HomeLayout";


class WatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      portal_user_id: "",
      watchlistdata: [],
      watch_list_load: false,
      market: {},
    };
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.handleGetWatchlists();
    }, 500);
  };
  handleGetWatchlists = () => {
    const token = localStorage.getItem("api_token");
    const portal_user_id = localStorage.getItem("portal_user_id");
    const { market } = this.props.setting;
    const watch_list = this.state.watchlistdata;
    const is_watch_list_load = this.state.watch_list_load;
    const requestData = {
      portal_user_id: portal_user_id,
      date: market ? market.open_date : "",
    };
    console.log("watch_list.length =", watch_list);
    if (market && portal_user_id && is_watch_list_load == false) {
      this.props.WatchlistShow(requestData).then((res) => {
        if (res.data.length && res.status == "success" && token) {
          this.setState({
            watchlistdata: res.data,
            is_watch_list_load: true,
          });
        }
      });
    }
  };
  static getDerivedStateFromProps(props, state) {
    return {
      header: props.headers,
      lang: props.lang,
      setting: props.setting,
      watchlistdata: props.watchlistdata,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleGetWatchlists();
    }
  }

  render() {
    var is_login = false;
    const { watchlistdata } = this.state;
    // console.log("watchlist Index", watchlistdata);
    const { setting } = this.state;
    const lang = this.props.lang;
    if (Object.keys(setting).length && setting.is_login) {
      is_login = setting.is_login;
    }
    return (
      <Fragment>
        <div className="row mt-2 mb-2">
          <div className="col-md-12">
            <div className="wathlist-wrap box">
            <div className="wathlist-title box-header">
             <p className="label">My Watchlists</p>
              {/* {is_login ? (
                  <p className="btn-txt text-right">+ Add Symbol</p>
                ) : null} */}
              </div> 
              {(is_login && watchlistdata.length) ? (
                <div className="table-wrap">
                  <div className="table-box">
                    <table>
                      <thead>
                        <tr>
                          <th>Symbol</th>
                          <th>LTP</th>
                          <th>YCP</th>
                          <th>P.Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {watchlistdata.map((row, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                {row.instrument && (
                                  <Link
                                    to={{
                                      pathname: `${row.instrument
                                          ? "/symbol/" + row.instrument.name
                                          : ""
                                        }`,
                                    }}
                                    className="rightsite-link"
                                  >
                                    {row.instrument.name}
                                  </Link>
                                )}
                              </td>
                              <td>{row.last_traded_price}</td>
                              <td>{row.yday_close_price}</td>
                              <td
                                className={
                                  row.pchange < 0
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {(Math.fround(row.pchange * 100) / 100).toFixed(
                                  2
                                )}{" "}
                                %
                              </td>
                            </tr>
                          );
                        })}
                        {/* {watchlistdata &&
                          watchlistdata.map((row, key) => (
                            <tr key={key}>
                              <td>
                                {row.name && (
                                  <Link
                                    to={{
                                      pathname: `${
                                        row.instrument_code
                                          ? "/symbol/" + row.instrument_code
                                          : ""
                                      }`,
                                    }}
                                    className="rightsite-link"
                                  >
                                    {row.instrument_code}
                                  </Link>
                                )}
                              </td>
                              <td>25.32</td>
                              <td>26.23</td>
                              <td>0.30%</td>
                            </tr>
                          ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <Fragment>
                  {(is_login && watchlistdata.length == 0) ? <Fragment>
                    <div className="watchlist-box">
                      <Fragment>
                        <p>You have no watch list</p>
                      </Fragment>
                    </div>
                  </Fragment> : <Fragment>
                    <div className="watchlist-box">
                      <Fragment>
                        <p>Sign-in to view your list and add symbols.</p>
                        <Link
                          to={{ pathname: `/login` }}
                          className="btn btn-primary font-bold m-1 sign-in-btn-blue"
                        >
                          Sign In
                        </Link>
                      </Fragment>
                    </div>
                  </Fragment>}
                </Fragment>
              )}
              {/* <div className='watchlist-box'>
                                    <p>Your list is empty.</p>
                                    <button className='btn-blue '>+ Add Symbol</button> 
                                    <p>Sign-in to view your list and add symbols.</p>
                                    <button className='btn-blue '>Sign In</button>
                            </div> */}
                {(is_login && watchlistdata.length) && <div className="row">
                <div className="col-md-11 text-right" style={{fontSize:12}}>
                <Link 
                    to={{ pathname: `/CompanyWatchlist` }}
                    className="view_all"
                  >
                    View All
                  </Link>
                </div>
              </div>}

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  market: state.utils.setting.market,
  watchlistdata: state.contents.watchlist,
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getSetting: (requestData) => dispatch(getSetting(requestData)),
  setLoginStatus: (requestData) => dispatch(setLoginStatus(requestData)),
  WatchlistShow: (requestData) => dispatch(WatchlistShow(requestData)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchList));

// const mapStateToProps = (state) => ({
//   headers: state.header,
//   setting: state.utils.setting,
//   lang: state.utils.lang,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setPath: (requestData) => {
//     dispatch(setPath(requestData));
//   },
//   setLayout: (requestData) => {
//     dispatch(setLayout(requestData));
//   },
//   setPathLayout: (requestData) => {
//     dispatch(setPathLayout(requestData));
//   },
//   setSearchKey: (requestData) => {
//     dispatch(setSearchKey(requestData));
//   },
//   getSetting: (requestData) => dispatch(getSetting(requestData)),
//   setLoginStatus: (requestData) => dispatch(setLoginStatus(requestData)),
//   WatchlistShow: (requestData) => dispatch(WatchlistShow(requestData)),
// });

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(WatchList)
// );
