import $ from "jquery";
import Cookies from "js-cookie";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  setLayout,
  setPath,
  setPathLayout,
  setSearchKey,
  getSetting,
} from "../../../actions/Utils";
import { base_path } from "../../../config/Api";
import RecentlyView from "./RecentlyView";
import SearchRightSide from "./SearchRightSide";
import StocksGainers from "./StocksGainers";
import StocksLosers from "./StocksLosers";
import TrendingTickers from "./TrendingTickers";
import WatchList from "./WatchList";
import PortalNews from "../../newsdetails/listnews"
// import CategoryNews from "../components/home/CategoryNews";
import CategoryNews from "../../home/CategoryNews"
import BlockTransaction from "./BlockTransaction";
// import { zaq_client_url } from "../../../config/Api";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  handleSubmit = (event) => {
    event.preventDefault();
    const { history, match } = this.props;
    const { search } = this.state;
  };

  render() {
    const lang = this.props.lang;
    // console.log("pathname =",pathname);

    return (
      <Fragment>
        <div className="row justify-content-end">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-end">

              {/* <Link to={{ pathname: `${zaq_client_url}` }} target='_blank' style={{ width: "100%", height: "90px" }}>
                <button
                  type="button"
                  className="btn btn-blue btn-lg mb-2 mt-0"
                  style={{
                    width: "100%",
                    height: "70px",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                >
                  TT
                </button>
              </Link> */}
            </div>
          </div>
        </div>
        {/* <SearchRightSide /> */}
        <WatchList />
        {/* <RecentlyView /> */}
        {/* <TrendingTickers />
        <StocksGainers />
        <StocksLosers /> */}
        <BlockTransaction />
        <CategoryNews categoryClass={"home-category"} />
        {/* <PortalNews /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  lang: state.utils.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  setSearchKey: (requestData) => {
    dispatch(setSearchKey(requestData));
  },
  getSetting: (requestData) => dispatch(getSetting(requestData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
