// import logo from './logo.svg';
import React, { Component, Fragment,Suspense,lazy   } from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";

// import Cookies from 'js-cookie';
// import * as qs from 'query-string';

import { addPushWebToken, getRoutes, getSetting, getMarket } from './actions/Utils';
import './App.css';
const Footer = lazy(() => import('./components/common/Footer'));
const CommonHeader = lazy(() => import('./components/common/header/Commonheader'));


import { base_path } from './config/Api';
import HomeLayout from './layout/Home';
import  TVChartContainer  from './components/TVChartContainer';

// Publication component end
import configureStore from './store/Configurstore';
import MinuteMatrixChart from './components/chart/minuteChart/index.js';
// import NewScreener from './components/stockscreener/NewScreener.js';





// import firebase from './config/Firebase';
// import { getMessaging, getToken } from "firebase/messaging";

// import firebase from 'firebase/compat/app';
// import { getMessaging, getToken ,onMessage} from "firebase/messaging";

// import Notifications from './components/Notifications/Notifications';

const Htmlhelmet = lazy(() => import('./components/common/HtmlHelmet'));

const PageNotFound = lazy(() => import('./page/Page404'));
const SymbolDetails = lazy(() => import('./layout/SymbolDetails'));
const DetailsNews = lazy(() =>import('../src/components/newsdetails/detailsnews'));
const AnalyticalTools = lazy(() =>import('./layout/AnalyticalTool'));

const NewsList = lazy(() =>import('./components/newsdetails/listnews'));
const AnnouncementList = lazy(() =>import('./components/announcement/announcementList'));
const MinutematrixChart = lazy(() =>import('../src/components/chart/minuteChart/index.js'));

const Companies = lazy(() =>import('./components/page/CompanyList'));
const Signup = lazy(() =>import('./components/page/Signup'));
const Login = lazy(() => import('./components/page/Login'));
const CorporateNews = lazy(() => import('./components/page/CorporateNews'));
const FinancialNews = lazy(() => import('./components/page/FinancialNews'));
const ForgetPassword = lazy(() => import('./components/page/ForgetPassword'));
const PasswordReset = lazy(() => import('./components/page/PasswordReset'));
const BlockTransactionList = lazy(() => import('./components/page/BlockTransactionList'));
const CompanyWatchListView = lazy(() => import('./components/page/CompanyWatchListView'));
const Testsparkline = lazy(() => import('./components/chart/sparkline/index'));
// const StockScreener = lazy(() =>import('./components/stockscreener/ScreenerHome.js'));
const MarketMap = lazy(() =>import('./components/chart/marketMap/index.js'));
// const Testsparkline = lazy(() => import('./components/chart/sparkline_test/index'));
const MarketInfo = lazy(() => import('./components/chart/marketInfo/index.js'));

const store = configureStore();

// const messagingab = getMessaging()
// onMessage(messagingab, (payload) => {
//   console.log("message firebsae payload",payload);
// })



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utils: store.getState().utils,
      routemenu: store.getState().utils.routemenu,
      pubroutemenu: store.getState().utils.pubroutemenu,
      notificaitonshow: false,
      notification: {
        title: '',
        body: ''
      }
    };


  }

  handlePushNotification = (token) => {
    var requestData = {
      device_id: token
    }
    store.dispatch(addPushWebToken(requestData)).then((res) => {
      // console.log("handle push notification", res);
    });
  }

  

  componentDidMount = () => {
      /*
          store.dispatch(getRoutes()).then((res) => {
            // console.log(" route data = ",res);
            this.setState({ routemenu: res })
          });
          
          // store.dispatch(getPubRoutes()).then((res) => {
          //   // console.log(" route data = ",res);
          //   this.setState({ pubroutemenu: res })
          // });

          store.dispatch(getSetting()).then((res) => {
            // console.log('Footer data = ',res);
          });

      */

          store.dispatch(getMarket()).then((res) => {
             //console.log('getMarket data = ',res);
          });

  }

  render() {
    // console.log('app routemenu js file = ',this.state.routemenu);
    const routemenu = [];//this.state.routemenu;
    const pubroutemenu = [];//this.state.pubroutemenu;

    var site = base_path.split("/");
    // let pathName = this.state.utils.path;
// console.log("site",site);
    var basename = "/";
    // if (site.includes("www.tiyait.com") || site.includes("3000")) {
    //   basename = "/";
    // }
    // else if (site.includes("stockportal")) {
    //   basename = "/stockportal/";
    // }
    
    // else {
    //   basename = "/";
    // }
    //  console.log(" Hello process.env.PUBLIC_URL =",process.env.PUBLIC_URL);
    // console.log('basename =',basename);
    //${process.env.PUBLIC_URL}
    return (
      <Router basename={basename}>

        <Fragment>
        <Suspense fallback={<div className="pageloader"></div>}>
        {/* <CommonHeader />
          <Testsparkline /> */}
          <CommonHeader />
          <Htmlhelmet />
          <Switch>
            <Route exact path={`/`} >
              <HomeLayout />
            </Route>
            <Route exact path={`/advanced-chart`} >
              <TVChartContainer />
            </Route>
            <Route exact path={`/announcement`} >
              <AnnouncementList />
            </Route>
            <Route exact path={`/companies`}>
              <Companies />
            </Route>
            <Route exact path={`/news-archive`}>
              <NewsList />
            </Route>
            <Route path={`/symbol/:slug`}>
              <SymbolDetails />
            </Route>
            <Route path={`/portal_news/:slug`}>
              <DetailsNews />
            </Route>
            <Route path={`/analyticaltools`}>
              <AnalyticalTools />
            </Route>
            <Route path={`/signup`}>
              <Signup />
            </Route>
            <Route path={`/login`}>
              <Login />
            </Route>
            <Route path={`/news/Corporate News`}>
              <CorporateNews />
            </Route>
            <Route path={`/news/Financial News`}>
              <FinancialNews />
            </Route>
            
            <Route path={`/forgot/password`}>
              <ForgetPassword />
            </Route>
            <Route path={`/reset/password/:slug`}>
              <PasswordReset />
            </Route>
            <Route path={`/blocktransaction/list`}>
              <BlockTransactionList />
            </Route>

            <Route path={`/CompanyWatchlist`}>
              <CompanyWatchListView />
            </Route>
            <Route path={`/minuteMatrixChart`}>
              <MinutematrixChart />
            </Route>
            <Route path={`/stockScreener`}>
              {/* <StockScreener /> */}
            </Route>
            <Route path={`/newScreener`}>
              {/* <NewScreener /> */}
            </Route>
            <Route path={`/marketMap`}>
              <MarketMap />
            </Route>
            <Route path={`/marketInfo`}>
              <MarketInfo />
            </Route>
            
            
            <Route path="404" component={<PageNotFound />} />
          </Switch>
          <Footer />
        
          </Suspense>
        </Fragment>
      </Router>
      
    );
  }
}

export default App;
