import qs, { stringify } from 'qs';
import axios from 'axios';
import axiosInstance, { caversion, version } from '../config/Api';

export const setPath = (path) => ({
  type: 'SET_PATH',
  path
});

export const setLayout = (id) => ({
  type: 'SET_LAYOUT_ID',
  id
});

export const setPathLayout = (data) => ({
  type: 'SET_PATH_LAYOUT',
  data
});

export const initHeader = (data) => ({
  type: 'INIT_HEADER',
  data
});

export const setHeaderLoading = (status) => ({
  type: 'SET_HEADER_LOADING',
  status
});

export const setFooterLoading = (status) => ({
  type: 'SET_FOOTER_LOADING',
  status
});

export const setRightSideLoading = (status) => ({
  type: 'SET_RIGHTSIDE_LOADING',
  status
});

export const getHeaderSection = () => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/header', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}

export const initTopMenuHeader = (data) => ({
  type: 'INIT_TOPMENU',
  data
});

export const getTopMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/topmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initTopMenuHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}

export const initRightSiteMenuHeader = (data) => ({
  type: 'INIT_RIGHTSITE',
  data
});

export const getRightSiteMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/rightmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initRightSiteMenuHeader(res.data));
      }

      return res.data;
    });
  }
}

export const initFooter = (data) => ({
  type: 'INIT_FOOTER',
  data
});


export const sendContactus = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}
export const sendInvestWithus = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendInvestWithus', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}
export const getaQuote = (parms) => {


  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res.data;
    });
  }
}
export const getFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/footer_navigation', qs.stringify()).then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}
export const getPubFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubfooter_navigation').then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}



export const contentAdded = (data, id) => ({
  type: 'ADDED_CONTENT',
  data: data,
  id: id
});

export const contentLoadStatus = (id) => ({
  type: 'CONTENT_LOAD_STATUS',
  id: id
});


export const sectionandContent = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contents?' + string, qs.stringify()).then(res => {
      // console.log("sectionandContent section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(contentAdded(res.data, parms.menu_id));
      }
      if (Object.keys(res.data).length === 0) {
        dispatch(contentLoadStatus(parms.menu_id));
      }
      return res;
    });
  }
}


export const initRoute = (data) => ({
  type: 'INIT_ROUTES',
  data
});

export const initpagename = (data) => ({
  type: 'INIT_PAGE_NAME',
  data
});


export const getRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/routes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initRoute(res.data));
        // console.log(" res.data = ",res.data);
        // dispatch(initpagename(res.data));
      }
      return res.data;
    });
  }
}

export const initPubRoute = (data) => ({
  type: 'INIT_PUBLICATION_ROUTES',
  data
});
export const getPubRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubroutes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initPubRoute(res.data));

      }
      return res.data;
    });
  }
}
export const setSetting = (data) => ({
  type: 'SET_SETTING',
  data
});
export const setSearchKey = (search) => ({
  type: 'SET_SEARCH_KEY',
  search
});

export const setSearchSlug = (slug) => ({
  type: 'SET_SEARCH_SLUG',
  slug:slug
});


export const getSetting = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/setting').then(res => {
      if (Object.keys(res.data).length) {
        dispatch(setSetting(res.data));
      }
      return res.data;
    });
  }
}

export const setMarketBasic = (data) => ({
  type: 'SET_MARKET_BASIC',
  data
})
export const setMarketSchedule = (data) => ({
  type: 'SET_MARKET_SCHEDULE',
  data
})
export const setInstrument = (data) => ({
  type: 'SET_INSTRUMENT',
  data
})

export const getMarket = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'market_info').then(res => {
      console.log("res.data =", res.data);
      if (Object.keys(res.data).length && res.data.status == 'success') {
        dispatch(setMarketBasic(res.data.data));
      }
      dispatch(setMarketSchedule(res.data.schedule));
      dispatch(setInstrument(res.data.instrument));
      
      return res.data;
    });
  }
}

export const setAllNavigation = (data) => ({
  type: 'INIT_HEADER',
  data
});

export const getAllNavigation = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/all_navigation').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const setTopNavigation = (data) => ({
  type: 'INIT_TOPMENU',
  data
});


export const getTopNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', { params: parms }).then(res => {
      if ((res.data).length) {
        dispatch(setTopNavigation(res.data));
      }
      return res.data;
    });
  }
}
export const setHeaderNavigation = (data) => ({
  type: 'INIT_HEADERMENU',
  data
});


export const getHeaderNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', { params: parms }).then(res => {
      if ((res.data).length) {
        dispatch(setHeaderNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const initSearchContent = (data) => ({
  type: 'INIT_SEARCH_CONTENT',
  data
});
export const addSearchContent = (data) => ({
  type: 'ADD_SEARCH_CONTENT',
  data
});



export const getsearch = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contentsearch?' + string).then(res => {

      if (Array.isArray(res.data)) {
        if (parms.initial === true) {
          dispatch(initSearchContent(res.data));
        }
        else {
          dispatch(addSearchContent(res.data));
        }

      }
      return res.data;
    });
  }
}


export const detailsContent = (data) => ({
  type: 'SET_DETAILS_CONTENT',
  data
});

export const getContentDetails = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/content-details?' + string).then(res => {

      dispatch(detailsContent(res.data));
      return res.data;
    });
  }
}






//Mobarak Hossen

export const SetHomeContentAdded = (data) => ({
  type: 'INIT_HOME_LAYOUT',
  data: data,

});

export const homeLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/home').then(({ data }) => {
      // console.log("sectionandContent section = ", data);
      if (Object.keys(data).length) {
        dispatch(SetHomeContentAdded(data));

        if (data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }

      }
      return data;

    });
  }
}
export const SetFaqContentAdded = (data) => ({
  type: 'INIT_FAQ_LAYOUT',
  data: data,

});

export const faqLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetFaqContentAdded(data));
      }
      return data;

    });
  }
}
export const SetContactusContentAdded = (data) => ({
  type: 'INIT_CONTACTUS_LAYOUT',
  data: data,

});

export const contactusLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetContactusContentAdded(data));
      }
      return data;

    });
  }
}
export const SetPremiumCalculatorContentAdded = (data) => ({
  type: 'INIT_PREMIUM_CALCULATOR_LAYOUT',
  data: data,

});

export const premiumCalculatorLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetPremiumCalculatorContentAdded(data));
      }
      return data;

    });
  }
}

export const SetPageContent = (data) => ({
  type: 'INIT_PAGE_LAYOUT',
  data: data,

});

export const PageContent = (parms) => {

  let requestData = {
    slug: "/" + parms
  }
  return (dispatch) => {
    return axiosInstance.get(version + 'get/page', { params: requestData }).then(({ data }) => {
      // console.log("data Mobarak=", data);
      if (Object.keys(data).length) {
        dispatch(SetPageContent(data));

        if (data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }
      }
      return data;

    });
  }
}




export const pageSectionContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {
      return data;

    });
  }
}




export const Search = (params) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'main_search', qs.stringify(params)).then(({ data }) => {

      return data;

    });
  }
}


export const initModalPopupAds = (data) => ({
  type: 'INIT_MODAL_POPUP_ADS',
  data: data,
});

export const getHomePageModal = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/modalPopup').then(({ data }) => {
      dispatch(initModalPopupAds(data));
      return data;

    });
  }
}



export const setMemberToken = (token) => ({
  type: 'SET_MEMBER_TOKEN',
  data: token,
});

export const userLogin = (requestData) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userlogin', requestData).then(({ data }) => {
      if (data.message) {
        dispatch(setMemberToken(data.accesstoken));
      }
      else {
        dispatch(setMemberToken(''));
      }
      return data;

    });
  }
}

export const addPushWebToken = (requestData) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'pushwebnitification', qs.stringify(requestData)).then(({ data }) => {
      return data;

    });
  }
}

export const SetMetaData = (data) => ({
  type: 'INIT_META_DATA',
  data: data,

});

export const getMetaData = (parms) => {

  return (dispatch) => {
    // console.log("get meta data =", parms);
    // return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {

    //   if (Object.keys(data).length) {
    //     dispatch(SetMetaData(data));
    //   }
    //   return data;

    // });
  }
}




export const SetChangeLanguage = (data) => ({
  type: 'SET_LANG',
  data: data
})


export const SetLayoutContentLoad = (data) => ({
  type: 'INIT_COMMON_CONTENT',
  data: data,

});

export const layoutContentLoad = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetLayoutContentLoad(data));
      }
      return data;

    });
  }
}
// export const customerPoricyNid = (parms) => {
//   //production  = nid-person
//   // for testing = test-nid-person-values

//   return (dispatch) => {
//     return axiosPorichoy.post('test-nid-person-values', parms).then(({ data }) => {

//       return data;

//     });
//   }
// }



export const newsSubscribe = (parms) => {
  
  return (dispatch) => {
    return axiosInstance.post(version + 'newsSubscribe', qs.stringify(parms)).then(res => {
      return res;
    });
  }

}

export const portalUserRegistration = (parms) => {
  
  return (dispatch) => {
    return axiosInstance.post(version + 'potal_user/register', qs.stringify(parms)).then(res => {
      return res;
    });
  }
}


export const userpanelDashboard = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/dashboard', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}


export const changeCustomerPassword = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/changepassword', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}

export const indexChart = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'indexchart', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const prevIndexChart = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'indexchart-range', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const symbolIndexChart = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'symbol-indexchart', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const todayTradStatistics = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'todaytradestatistics', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const tenDaysTradeStatistics = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'tendaystradestatistics', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const minuteChartMatrix = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'minute_chart', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const indexMoverPullers = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'index-movers-pullers', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}
export const indexMoverDraggers = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'index-movers-draggers', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const sectorTotalValueChart = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'sector-total-values', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const setStrengthMeter = (data) => ({
  type: 'INIT_STRENGTH_METER',
  data: data,

});


export const StrengthMeterData = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'strength_meter', { params: parms }).then(({ data }) => {
     
      if (Object.keys(data).length && data.status == 'success') {
        console.log("data setStrengthMeter = ",data);
          dispatch(setStrengthMeter(data.data));
      }
      return data;
    });
  }
}

export const TopTradeData = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version +'top_trade',{ params: parms }).then(({ data }) => {
      return data;
    });
  }
}


export const sectorUpDownChart = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'sector-up-down', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}

export const TradeValueChart = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'trade_value', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}


export const setRecentlyView = (data) => ({
  type: "INIT_RECENT_VIEW",
  data
})
export const recentlyView = (parms) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'recent_view', qs.stringify(parms)).then(({ data }) => {
      if ((Object.keys(data).length) && data.status == 'success') {
        dispatch(setRecentlyView(data.data));
      }
      return data;

    });
  }
}

export const setBlockTransaction = (data) => ({
  type: "INIT_BLOCK_TRANSACTION",
  data:data
});

export const ShowBlockTransaction = (params) => (dispatch) => {
  return axiosInstance.post(version + 'block_transaction', qs.stringify(params))
    .then(({ data }) => {
      if (data && data.status === 'success') {
        dispatch(setBlockTransaction({data:data.data,rdate:data.rdate}));
      }
      return data;
    })
};


export const setTrendingTicker = (data) => ({
  type: "INIT_TRENDING_TICKERS",
  data
})
export const trendingTickerApi = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'trading_ticker', qs.stringify(parms)).then(({ data }) => {
      if ((Object.keys(data).length) && data.status == 'success') {
        dispatch(setTrendingTicker(data.data));
      }
      return data;

    });
  }
}

export const setStockGainer = (data) => ({
  type: "INIT_STOCK_GAINER",
  data
})

export const stockGainerApi = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'top_gainer_op_ltp', qs.stringify(parms)).then(({ data }) => {
      if ((Object.keys(data).length) && data.status == 'success') {
        dispatch(setStockGainer(data.data));
      }
      return data;

    });
  }

}

export const setStockLoser = (data) => ({
  type: "INIT_STOCK_LOSER",
  data
})

export const stockLoserApi = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'top_loser_op_ltp', qs.stringify(parms)).then(({ data }) => {
      if ((Object.keys(data).length) && data.status == 'success') {
        dispatch(setStockLoser(data.data));
      }
      return data;

    });
  }
}

export const mostActivity = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'most_active', { params: parms }).then(({ data }) => {
      return data;
    });
  }
}




export const sparklineGainer = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'gainer-sparkline', qs.stringify(parms)).then(({ data }) => {
      
      return data;

    });
  }

}
export const sparklineLooser = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'looser-sparkline', qs.stringify(parms)).then(({ data }) => {
      
      return data;

    });
  }

}
export const sparklineTopVolume = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'topvolume-sparkline', qs.stringify(parms)).then(({ data }) => {
      
      return data;

    });
  }

}
export const sparklineTopValue = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'topvalue-sparkline', qs.stringify(parms)).then(({ data }) => {
      
      return data;

    });
  }

}
export const sparklineTopTrade = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'toptrade-sparkline', qs.stringify(parms)).then(({ data }) => {
      
      return data;

    });
  }

}