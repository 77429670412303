
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { TopTradeData, setPathLayout } from '../../actions/Utils';
import { Link } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

class TopTrade extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      trade_data: [],
      trade_data_load: false,
    }
  }

  componentDidMount = () => {
    this.handleTopTradeData();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting
    }
  }

  componentDidUpdate(prevProps, state) {
    if (prevProps.setting !== this.props.setting) {
      this.handleTopTradeData();
    }
    else {
      console.log("top trade state=", state);
    }
  }

  handleTopTradeData = () => {
    const { market } = this.props.setting;
    let requestData = {
      tdate: market ? market.open_date : ''
    };

    if (market) {
      this.props.TopTradeData(requestData).then((res) => {
        this.setState({
          trade_data: [...res],
          trade_data_load: true
        });
      });
    }
  }
  render() {
    const { trade_data, trade_data_load } = this.state;
    return (
      <Fragment>
        <div className='box'>
          <div className='box-header'>Top Trade</div>
          <div className='box-body' style={{ height: "395px" }}>
            <div className='rb-tabs-content-item scrolling-add'>
              <div className='table-responsive' style={{ maxHeight: '380px', overflow: 'auto' }}>
                <table className='table table-striped table-borderless'>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>LTP</th>
                      <th>Total Trades</th>
                      <th>YCP</th>
                      <th>IND CHG</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trade_data_load &&
                      trade_data.length > 0 &&
                      trade_data.map((row, key) => (
                        <Fragment key={key}>
                          <tr>
                            <td>
                              {row.instrument.name && (
                                <Link
                                  to={{ pathname: `/symbol/${row.instrument.name}` }}
                                  className="rightsite-link"
                                >
                                  {row.instrument.name}
                                </Link>
                              )}
                            </td>
                            <td>{parseFloat(row.last_traded_price).toFixed(2)}</td>
                            <td>{parseFloat(row.total_trades).toFixed(2)}</td>
                            <td>{parseFloat(row.yday_close_price).toFixed(2)}</td>
                            <td>{parseFloat(row.pchange).toFixed(2)}</td>
                          </tr>
                        </Fragment>
                      ))}
                    {!trade_data_load && <tr>
                      <td colSpan={5}><ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} /></td>
                    </tr>}

                    {trade_data_load && !trade_data.length && <tr>
                      <td colSpan={5}>No record found.</td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.utils.lang,
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  TopTradeData: (requestData) => dispatch(TopTradeData(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TopTrade);