import axios from 'axios';
import API from '../config/Api';
import qs from 'qs';
import axiosInstance, { version } from '../config/Api';
import { deleteDatabase } from 'workbox-core/_private';


export const setBreakingNews = (data) => ({
  type: 'INIT_BREAKING_NEWS',
  data
})

export const breakingNews = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/breaking_news', { params: params }).then(({ data }) => {

      if ((Object.keys(data).length) && data.status == 'success') {
        console.log("breaking news =", data);
        dispatch(setBreakingNews(data.data));
      }
      return data;

    });
  }
}

export const setMarketbulleting = (data) => ({
  type: 'INIT_MARKET_BULLETING',
  data
})

export const setMarketMap = (data) => ({
  type: 'INIT_MARKET_MAP',
  data
})


export const marketBulletingApi = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/market_bulleting', { params: params }).then(({ data }) => {

      if ((Object.keys(data).length) && data.status == 'success') {
        dispatch(setMarketbulleting(data.data));
      }
      return data;

    });
  }
}
export const marketMapApi = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/market_map', { params: params }).then(({ data }) => {
      console.log("homelayout market map api =",data);
      return data;
    });
  }
}

export const setHightlightNews = (data) => ({
  type: 'INIT_HIGHLIGHT_NEWS',
  data
})

export const highlightNews = (params) => {
    return (dispatch) => {
      return axiosInstance.get(version + 'get/highlight_news', { params: params }).then(({ data }) => {
        if ((Object.keys(data).length) && data.status == 'success') {
         
          dispatch(setHightlightNews(data.data));
        }
        return data;

      });
    }
}
export const setHightlightNewsCategory = (data) => ({
  type: 'INIT_HIGHLIGHT_NEWS_CATEGORY',
  data
})

export const highlightNewsCategory = (params) => {
    return (dispatch) => {
      return axiosInstance.get(version + 'get/highlight_news_category', { params: params }).then(({ data }) => {
        if ((Object.keys(data).length) && data.status == 'success') {
         
          dispatch(setHightlightNewsCategory(data.data));
        }
        return data;

      });
    }
}

export const setNewsCategoryName = (data) => ({
  type: 'INIT_NEWS_CATEGORY_NAME',
  data
})

export const newsCategoryName = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/news_category_name', { params: params }).then(({ data }) => {
      if ((Object.keys(data).length) && data.status == 'success') {
       
        dispatch(setNewsCategoryName(data.data));
      }
      return data;

    });

  }
}

export const categoryNewsByID = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'get/news_category', qs.stringify(params)).then(res => {
      return res.data;
    });
  }
}

export const allNews = (params) => {
  return () => {
    const queryParams = { ...params };
    if (queryParams.offset !== undefined) {
      queryParams.offset = parseInt(queryParams.offset);
    }
    return axiosInstance.get(version + 'get/all_news', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const announcement = (params) => {
  return () => {
    const queryParams = { ...params };
    if (queryParams.offset !== undefined) {
      queryParams.offset = parseInt(queryParams.offset);
    }
    return axiosInstance.get(version + 'announcement', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const companyData = (params) => {
  return () => {
    const queryParams = { ...params };
    if (queryParams.offset !== undefined) {
      queryParams.offset = parseInt(queryParams.offset);
    }
    return axiosInstance.get(version + 'company/list', { params: params }).then(res => {
      return res.data;
    });
  }
}

// export const setcompanyList = (data) => ({
//   type: 'INIT_COMPANIES_LIST',
//   data
// })

// export const companyList = (params) => {
//   return (dispatch) => {
//     return axiosInstance.get(version + 'company/list', { params: params }).then(({ data }) => {
//       if ((Object.keys(data).length) && data.status == 'success') {
//         console.log("comnpanies list =", data);
//         dispatch(setcompanyList(data.data));
//       }
//       return data;
//     });
//   }
// }

// export const CompanySearch = (params) => {
//   return (dispatch) => {
//     return axiosInstance.post(version + 'company_search', qs.stringify(params)).then(({ data }) => {
//       return data;
//     });
//   }
// }

export const CompanySearch = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'company_search', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const singleNews = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/news', { params: params }).then(res => {
      return res.data;
    });
  }
}
export const corporateNews = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/corporate_news', { params: params }).then(res => {
      return res.data;
    });
  }
}
export const indexSummeryInfo = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/index_summery', { params: params }).then(res => {
      console.log("IndexSummeryInfo list =", params);
      return res.data;
    });
  }
}

export const corporateNewsSearch = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'corporate_news_search', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const financialNewsSearch = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'financial_news_search', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const financialNews = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/financial_news', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const BlockTransactionlisting = (params) => {
  return () => {
    const queryParams = { ...params };
    if (queryParams.offset !== undefined) {
      queryParams.offset = parseInt(queryParams.offset);
    }
    return axiosInstance.get(version + 'block_transaction_list', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const BlockTransactionSearch = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'block_transaction_search', { params: params }).then(res => {
      return res.data;
    });
  }
}

export const setLoginStatus = (data) => ({
  type: 'SET_LOGIN_STATUS',
  data
})

export const portalUserLogin = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'portal_user/login', qs.stringify(params)).then(({ data }) => {
      
      if(data.status === 'success') {
        dispatch(setLoginStatus(1));
      }
      else {
        dispatch(setLoginStatus(0));
      }
      
      return data;
    });
  }
}

export const portalUserLogout = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'logout', qs.stringify(params)).then(({ data }) => {
      return data;
    });
  }
}

export const passwordResetLink = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'forgot-password', qs.stringify(params)).then(({ data }) => {
      return data;
    });
  }
}
export const addToWatchlist = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'watchlist', qs.stringify(params)).then(({ data }) => {
      return data;
    });
  }
}

export const setWatchlistShow= (data) => ({
  type: "INIT_WATCHLIST",
  data
});

export const WatchlistShow = (params) => (dispatch) => {
  return axiosInstance.post(version + 'watchlistIndex', qs.stringify(params))
    .then(({ data }) => {
      if (data && data.status === 'success') {
        dispatch(setWatchlistShow(data.data));
      }
      return data;
    })
};

export const showResetData = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'password/reset', { params: params }).then(res => {
      return res;
    });
  }
}
export const Resetpassword = (params) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'reset_password', qs.stringify(params)).then(({ data }) => {
      return data;
    });
  }
}
export const ShowBlockTransaction = (params) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'block_transaction', { params: params }).then(res => {
      return res;
    });
  }
}
// export const setToken = (token) => ({
//   type: 'SET_TOKEN',
//   data: token,
// });

// export const userLogin = (requestData) => {
//   return (dispatch) => {
//     return axiosInstance.post(version + 'userlogin', requestData).then(({ data }) => {
//       if (data.message) {
//         dispatch(setToken(data.accesstoken));
//       }
//       else {
//         dispatch(setToken(''));
//       }
//       return data;

//     });
//   }
// }
