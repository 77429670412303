import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library';
import { withRouter } from "react-router-dom";
import { base_path, host_name } from '../../config/Api';
import Datafeed from './Chartapi/datafeed'



function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
var tvsite = host_name.split(".");

class TVChartContainer extends React.PureComponent {

	static defaultProps = {
		symbol: 'DSEX',   
		interval: '1D',
		datafeedUrl:  Datafeed,
		// datafeedUrl: tvsite.includes("shebacapital") ? 'https://shebacapital.com/trade_admin/api/v1' : 'http://127.0.0.1:8000/api/v1',
		// datafeedUrl: 'https://shebacapital.com/trade_admin/api/v1',
		// datafeedUrl: 'https://demo-feed-data.tradingview.com',
		libraryPath: tvsite.includes("shebacapital") ? '/charting_library/' : '/charting_library/',
		// libraryPath: '/stockportal/charting_library/',
		chartsStorageUrl: 'https://portal.shebacapital.com/',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		theme: "light",
		style: "1",
		locale: "en",
		enable_publishing: false,
		allow_symbol_change:true,
		debug: tvsite.includes("shebacapital") ?false:true,
		timezone: 'Asia/Almaty', 
		enabled_features: ["header_in_fullscreen_mode","side_toolbar_in_fullscreen_mode"],
		time_frames: [
			{ text: "5Y", resolution: "1W", description: "5 Years" },
			{ text: "3Y", resolution: "1W", description: "3 Years", title: "3Y" },
			{ text: "2Y", resolution: "1D", description: "2 Years" },
			{ text: "1Y", resolution: "1D", description: "1 Years" },
			{ text: "6M", resolution: "1D", description: "6 Months" },
			{ text: "4M", resolution: "1D", description: "4 Months" },
			{ text: "3M", resolution: "1D", description: "3 Months" },
			{ text: "1M", resolution: "1D", description: "1 Month" },
			{ text: "15D", resolution: "1D", description: "15 Days" },
			{ text: "10D", resolution: "1D", description: "10 Days" },
			{ text: "5D", resolution: "15m", description: "5 Days" },
			{ text: "3D", resolution: "5m", description: "3 Days" },
			{ text: "1D", resolution: "1m", description: "1 Day" },
			
		]
		
	};

	tvWidget = null;

	constructor(props) {
		super(props);

		this.ref = React.createRef();
		this.handleChangechart = this.handleChangechart.bind(this);
	}
	componentDidMount() {
		
		if(this.props.match.path === '/advanced-chart' ) {
			this.ref.current?.scrollIntoView({ behavior: 'smooth' });
		}
		
		const slug = this.props.slug ? this.props.slug : this.props.symbol;


		const widgetOptions = {
			symbol: slug,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: this.props.datafeedUrl,
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			// disabled_features: ["items_favoriting", "show_object_tree","header_symbol_search "],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			timezone:this.props.timezone,
			theme: "light",
			style: "1",
			locale: "en",
			enable_publishing: false,
			studies_overrides: {
				// "moving average.plot.color": "rgb(0, 255, 255)"
			},
			enabled_features:this.props.enabled_features,
			time_frames:this.props.time_frames
			
			
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		
		
		tvWidget.onChartReady(() => {
			tvWidget.activeChart().createStudy('Moving Average', true, true, { length: 10 },
				// {'Plot.color': 'rgb(150, 95, 196)'},
			),
			tvWidget.activeChart().createStudy(
				'Relative Strength Index'
			);
			tvWidget.activeChart().createStudy(
				'MACD'
			);
			
			
		});

		

	}

	componentWillUnmount() {
		// console.log("advanched chart componentWillUnmount");
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	componentDidUpdate(prevProps) {
		console.log('tv chart render 01 =',prevProps)
		if (prevProps.slug !== this.props.slug) {
			if (this.tvWidget !== null) {
				this.tvWidget = null;
			}
			this.handleChangechart(this.props.slug);
		}
	}

	handleChangechart(slug) {



		let widgetOptions = {
			symbol: slug,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: this.props.datafeedUrl,
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			// disabled_features: [],
			// enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			timezone:this.props.timezone,
			theme: "light",
			style: "1",
			locale: "en",
			enable_publishing: false,
			enabled_features:this.props.enabled_features,
			time_frames:this.props.time_frames
		
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;
		tvWidget.onChartReady(() => {
			tvWidget.activeChart().createStudy('Moving Average', true, true, { length: 10 },
				// {'Plot.color': 'rgb(150, 95, 196)'},
			),
			tvWidget.activeChart().createStudy(
				'Relative Strength Index'
			);
			tvWidget.activeChart().createStudy(
				'MACD'
			);
			
			
		});
	}



	render() {
		console.log("tv chart render =",this.props);
		return (
			<div className="container">

			<div
				ref={this.ref}
				className={'TVChartContainer'}
			/>
			</div>
		);
	}
}

// export { TVChartContainer } 
export default withRouter(TVChartContainer)