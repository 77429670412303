import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  setPath,
  setLayout,
  setPathLayout,
  indexChart,
} from "../../../actions/Utils";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import TodaysChart from "./TodaysChart";
import TenDaysList from "./TenDaysList";
import TenDaysChart from "./TenDaysChart";

class TradingStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.chartRef = React.createRef();
  }

  render() {
    // console.log("State data=",this.state.dataPoints);

    const { isLoaded } = this.state;

    return (
      <Fragment>
        <div className="box">
          <div className="box-header"> Trade Statistics </div>
          <div className="box-body">
            <Tabs
              activeTab="1"
              className="tradingstatistics"
              ulClassName=""
              activityClassName="bg-success"
              onClick={(event, tab) => console.log(event, tab)}
            >
              <Tab title="Chart" className="">
                <TodaysChart />
              </Tab>
              <Tab title="Today's" className="">
                <div className="mt-3">
                  <TenDaysChart />
                </div>
              </Tab>

              <Tab title="10 Days" className="">
                <TenDaysList />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  indexChart: (requestData) => dispatch(indexChart(requestData)),
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TradingStatistics);
