import axios from 'axios';

var token = localStorage.getItem("token")?'Token '+localStorage.getItem("token"):'';
// export default axios.create({
//   // baseURL: `http://jsonplaceholder.typicode.com/`
//   baseURL: `http://127.0.0.1:8000/`,
//   headers: {'Authorization': token}
// });

// const axiosInstance = axios.create();

const axiosInstance = axios.create({
    auth: {
      username: "mobarak",  
      password: 'Sheba@mobarak#321'
    }
  });
  // const axiosInstance = axios.create({
  //   auth: {
  //     username: 'hgsecurities',  
  //     password: 'hgsecuritiess'
  //   }
  // });
//  axiosInstance.defaults.baseURL = 'http://192.168.2.108/sheba_trade_admin/api/';
// axiosInstance.defaults.baseURL = 'https://admin.itembuz.com/api/';
// axiosInstance.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axiosInstance.defaults.baseURL = 'https://shebacapital.com/trade_admin/api/';
export const ApiBaseURL = 'https://shebacapital.com/trade_admin/api/';


// axiosInstance.defaults.headers.common['Authorization'] = token;
// axiosInstance.defaults.headers.common['User-ID'] = '';

export const version = 'v1/';
export const imagePath = 'v1/';


//  export const image_url = 'http://192.168.2.108/sheba_trade_admin/';
// export const image_url = 'https://admin.itembuz.com/';
//  export const image_url = 'http://127.0.0.1:8000/';
export const image_url = 'https://shebacapital.com/trade_admin/';


export const base_path = '/';
// export const host_name = 'http://localhost:3000/';


// export const host_name = 'https://itembuz.com/';
export const host_name = 'http://localhost:3000/';
// export const tvs_chart = 'https://admin.itembuz.com/api/v1';
// export const tvs_chart = 'http://127.0.0.1:8000/api/v1';
export const tvs_chart = 'https://shebacapital.com/trade_admin/api/v1';


export const encryption_salt="LIC@2022";
export const google_recaptcha_site_key = '6Le2E5gaAAAAADPCWtOtzWkaBwQ1jAlPtjP7XRuK';

export default axiosInstance;

