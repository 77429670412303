const HeaderReducer = {
    top_menu: [],
    header_menu: [],
    rightsite: [],
    popupmodalads:{},
    breaking_news:[],
}

export default (state = HeaderReducer, action) => {
    switch (action.type) {
        case 'INIT_HEADER':
            return {
                ...state,
                ...action.data
            }
        case 'INIT_TOPMENU':

            return {
                ...state,
                top_menu: action.data
            }
        case 'INIT_HEADERMENU':
            return {
                ...state,
                header_menu: action.data
            }
        case 'INIT_RIGHTSITE':
            return {
                ...state,
                rightsite: action.data
            }
        case 'INIT_MODAL_POPUP_ADS':
            return {
                ...state,
                popupmodalads: action.data
            }
        case 'INIT_BREAKING_NEWS':
            // console.log('INIT_BREAKING_NEWS',action)
            return {
                ...state,
                breaking_news: action.data
            }
        case 'INIT_MARKET_BULLETING':
            // console.log('INIT_MARKET_BULLETING',action)
            return {
                ...state,
                market_bulleting: action.data
            }
            case 'INIT_MARKET_MAP':
            // console.log('INIT_MARKET_MAP',action)
            return {
                ...state,
                market_map: action.data
            }
      
      
        default:
            return state;

    }
}