import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout, sectorTotalValueChart } from '../../../actions/Utils'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment';

var names = ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas', 'Maria', 'Leon', 'Anna', 'Tim', 'Laura'];

function getRandomData() {
    var data = [];

    for (var i = 0; i < 10; i++) {
        data.push([names[i], Math.random() * 10]);
    }

    return data;
}


class SectorTotalValue extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            response: {
                data: [],
                loading: false
            },
            isLoaded: false,
            ctime: new Date(),
            call_request_data: 0
        };
        this.chartRef = React.createRef();
    }

    componentDidMount() {

        this.handleSectorTotalValues();
        this.indexChartID = setInterval(
            () => this.handleUpdateSectorTotalValues(),
            10000
        );

        // setInterval(function () {
        //     this.chartRef.series[0].setData(
        //         getRandomData(),
        //         true,
        //         { duration: 2000 }
        //     );
        // }, 3000);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            setting: props.setting
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.setting !== this.props.setting) {
            this.handleSectorTotalValues();
        }
    }

    componentWillUnmount() {
        clearInterval(this.indexChartID);
    }

    handleSectorTotalValues = () => {

        const { market } = this.props.setting;
        let requestData = {
            tdate: market ? market.open_date : ''
        };
        if (market) {
            this.props.sectorTotalValueChart(requestData).then((res) => {

                this.setState({
                    isLoaded: true,
                    response: {
                        ...this.state.response,
                        data: res,
                    }
                });


            });
        }
    }

    handleUpdateSectorTotalValues = () => {
        const { market } = this.props.setting;

        let requestData = {
            tdate: market ? market.open_date : ''
        };
        if (market) {
            this.props.sectorTotalValueChart(requestData).then((res) => {


                const { call_request_data } = this.state;
                this.setState({
                    response: {
                        ...this.state.response,
                        data: res,
                    },
                    call_request_data: res.data.length ? 0 : call_request_data + 1
                });
            });
        }
    }




    chartCallback = () => {
        console.log("Hello chartCallback");
    }

    render() {

        const { response } = this.state;
        const { data } = response.data;
        const { market } = this.props.setting;
        console.log("sector total value js =",market);
        var seriesdata = [];
        if (data && data.length) {
            data.map((row) => {
                seriesdata.push([row.name, row.value]);
            });
        }



        // let cdata = new Date();
        // let currentdate = cdata.toISOString().split('T')[0];
        
        let cdata = market?market.open_date:'';
        let currentdate = market?Moment(cdata).format('DD-MM-YYYY'):'';

        const options = {
            chart: {
                type: 'bar',
                marginLeft: 150,
                height: 500,
                backgroundColor: 'transparent',
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'category',
                min: 0,
                labels: {
                    animate: true
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Date : ' + currentdate,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' ',
                shared: false,
                useHTML: true,
                shadow: false,
                // pointFormat: '<b  style="color:black">{point.y}</b>',
                headerFormat: '<div class="rhm-highchart-tooltip">',
                pointFormat: '<p>{point.name}: </p>' +
                    '<p><b>{point.y} </b></p>',
                footerFormat: '</div>'
            },
            legend: {
                enabled: false
            },
            series: [{
                zoneAxis: 'x',
                zones: [{
                    value: 1,
                    color: '#ff4d40'
                }],
                dataLabels: {
                    enabled: true,
                    // format: '{y:,.2f}',
                    formatter: function () {
                        if (this.y > 1000000) {
                            return Highcharts.numberFormat(this.y / 1000, 2) + "M"
                        } else if (this.y > 1000) {
                            return Highcharts.numberFormat(this.y / 1000, 2) + "K";
                        } else {
                            return this.y
                        }
                    }
                },
                dataSorting: {
                    enabled: true,
                    sortKey: 'y'
                },
                // data: getRandomData()
                data: seriesdata

            }]


        }

        //   console.log("options=",options)
        return (

            <div className='box'>
                <div className='box-header'> Sectors Total Value </div>
                <div className='box-body' style={{ height: '520px' }}>
                    <Fragment>
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'chart'}
                            options={options}
                            allowChartUpdate={true}
                            containerProps={{ className: 'sectortotalvalue' }}
                            ref={this.chartRef}
                        />
                    </Fragment>
                </div>
            </div>
            // <Fragment>
            //     <HighchartsReact
            //         highcharts={Highcharts}
            //         constructorType={'chart'}
            //         options={options}
            //         allowChartUpdate={true}
            //         containerProps={{ className: 'sectortotalvalue' }}
            //         ref={this.chartRef}
            //     />
            // </Fragment>

        );
    }
}




const mapStateToProps = (state) => ({
    setting: state.utils.setting,
})
const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    sectorTotalValueChart: (requestData) => dispatch(sectorTotalValueChart(requestData)),
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) }
})



export default connect(
    mapStateToProps, mapDispatchToProps
)(SectorTotalValue);