import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout, sectorUpDownChart } from '../../../actions/Utils'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment';



class SectorUpDown extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            response: {
                data: {},
                loading: false
            },
            isLoaded: false,
            ctime: new Date(),
            call_request_data: 0
        };
        this.chartRef = React.createRef();
    }

    componentDidMount() {

        this.handleSectorUpDown();
        this.indexChartID = setInterval(
            () => this.handleUpdateSectorUpDown(),
            10000
        );

        // setInterval(function () {
        //     this.chartRef.series[0].setData(
        //         getRandomData(),
        //         true,
        //         { duration: 2000 }
        //     );
        // }, 3000);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            setting: props.setting
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.setting !== this.props.setting) {
            this.handleUpdateSectorUpDown();
        }
    }


    componentWillUnmount() {
        clearInterval(this.indexChartID);
    }

    handleSectorUpDown = () => {
        const { market } = this.props.setting;

        let requestData = {
            tdate: market ? market.open_date : ''
        };

        if (market) {
            this.props.sectorUpDownChart(requestData).then((res) => {
                this.setState({
                    isLoaded: true,
                    response: {
                        ...this.state.response,
                        data: res,
                    }
                });
            });
        }
    }

    handleUpdateSectorUpDown = () => {
        const { market } = this.props.setting;

        let requestData = {
            tdate: market ? market.open_date : ''
        };
        if (market) {
            this.props.sectorUpDownChart(requestData).then((res) => {

                const { call_request_data } = this.state;
                this.setState({
                    response: {
                        ...this.state.response,
                        data: res,
                    },
                    call_request_data: res.data.length ? 0 : call_request_data + 1
                });
            });
        }
    }




    chartCallback = () => {
        console.log("Hello chartCallback");
    }

    render() {
        const { response } = this.state;
        const { data } = response.data;
        const { market } = this.props.setting;
        // console.log("sector up down value js =",data);
        // console.log("sector up down value response =",response);
        var category = [];
        var up = [];
        var down = [];
        var flat = [];
        var seriesdata = [];
        if (data && data.length) {
            data.map((row) => {
                category.push([row.name]);
                up.push(Number(row.up));
                down.push(Number(row.down));
                flat.push(Number(row.flat));
                // seriesdata.push([row.name, row.value]);
            });

            seriesdata = [{
                name: 'Flat',
                color: 'yellow',
                data: flat
            }, {
                name: 'Down',
                color: 'red',
                data: down
            }, {
                name: 'Up',
                color: '#347deb',
                data: up
            }];
        }

        // console.log("seriesdata =",seriesdata);


        // let cdata = new Date();
        // let currentdate = cdata.toISOString().split('T')[0]
        let cdata = market?market.open_date:'';
        let currentdate = market?Moment(cdata).format('DD-MM-YYYY'):'';

        const options = {
            chart: {
                type: 'bar',
                marginLeft: 150,
                height: 500,
                backgroundColor: 'transparent',
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: category
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Date : ' + currentdate,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            legend: {
                reversed: true,
                align: "center",
                verticalAlign: "top",
                layout: "horizontal",

            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: seriesdata,
            tooltip: {
                useHTML: true,
                className:'rhm-highchart-tooltip'
            },
        }

        //   console.log("options=",options)
        return (

            <Fragment>
                <div className='box'>
                    <div className='box-header'> Sector Up Down </div>
                    <div className='box-body' style={{ height: '520px' }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'chart'}
                            options={options}
                            allowChartUpdate={true}
                            containerProps={{ className: 'sectorupdown' }}
                            ref={this.chartRef}
                        />
                    </div>
                </div>

                {/* <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'chart'}
                            options={options}
                            allowChartUpdate={true}
                            containerProps={{ className: 'sectorupdown' }}
                            ref={this.chartRef}
                        /> */}
            </Fragment>

        );
    }
}




const mapStateToProps = (state) => ({
    setting: state.utils.setting,
})
const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    sectorUpDownChart: (requestData) => dispatch(sectorUpDownChart(requestData)),
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) }
})



export default connect(
    mapStateToProps, mapDispatchToProps
)(SectorUpDown);