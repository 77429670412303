import React,{Fragment} from 'react'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

import ReactTimeAgo from 'react-time-ago'

export default function DatetimeAgo({ date }) {
  return (
   <Fragment>
        <ReactTimeAgo date={date} locale="en-US"/>
   </Fragment>
     
   
  )
}