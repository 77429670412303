import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import {
  setPath,
  setLayout,
  setPathLayout,
  minuteChartMatrix,
} from "../../../actions/Utils";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class MinuteChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      minuteData: [],
      isLoaded: false,
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.handleminuteChartMatrix();
    this.minuteChartID = setInterval(
      () => this.handleUpdateminuteChartMatrix(),
      60000
    );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedInstrument !== this.props.selectedInstrument) {
      this.handleminuteChartMatrix();
    }
  }

  componentWillUnmount() {
    clearInterval(this.minuteChartID);
  }

  handleminuteChartMatrix = () => {
    const { instrument, market } = this.props.setting;
    const { selectedInstrument } = this.props; // Assuming selectedInstrument is passed as a prop

    let requestData = {
      q: selectedInstrument, // Include the selected instrument in the requestData
      tdate: market ? market.open_date : "",
      trade_batch: market ? market.trade_batch : "",
    };

    if (market) {
      this.props
        .minuteChartMatrix(requestData)
        .then((res) => {
          if (res && res.data && res.data.minuteChartData) {
            this.setState(
              {
                isLoaded: true,
                minuteData: res.data.minuteChartData,
                instrumentName: instrument,
              },
              () => {
                //console.log("minuteData:", this.state.instrumentName);
              }
            );
          } else {
            console.error("Invalid response data structure:", res);
          }
        })
        .catch((error) => {
          console.error("Error fetching minute chart data:", error);
        });
    }
  };

  handleUpdateminuteChartMatrix = () => {
    const { selectedInstrument } = this.props;
    const { market } = this.props.setting;
    let requestData = {
      q: selectedInstrument,
      tdate: market ? market.open_date : "",
      trade_batch: market ? market.trade_batch : "",
    };
  
    this.props.minuteChartMatrix(requestData).then((res) => {
      if (res && res.data && res.data.minuteChartData) {
        this.setState({
          minuteData: res.data.minuteChartData,
        });
      } else {
        console.error("Invalid response data structure:", res);
      }
    }).catch((error) => {
      console.error("Failed to update minute chart data:", error);
    });
  };
  

  render() {
    const { isLoaded, minuteData, instrumentName } = this.state;

    if (isLoaded && minuteData && minuteData.length > 0) {
      const bullVolumes = minuteData[0].bullVolume;
      console.log("bull volume", bullVolumes);
    } else {
      console.log("Data is not loaded or minuteData is empty");
    }

    const calculateVolume = (data, type) => {
      if (!data || !Array.isArray(data)) return 0;
      return data.reduce((total, item) => {
        const volume = parseFloat(item[type]);
        return isNaN(volume) ? total : total + volume;
      }, 0);
    };

    const volumeChanges = minuteData.map((data) =>
      parseFloat(data.volume_change)
    );
    const pubLastTradedPrices = minuteData.map((data) =>
      parseFloat(data.pub_last_traded_price)
    );

    let totalBearVolumeChange = 0;
    let totalNeutralVolumeChange = 0;
    let totalBullVolumeChange = 0;
    for (let i = 1; i < minuteData.length; i++) {
      if (minuteData[i].pub_last_traded_price === minuteData[i - 1].pub_last_traded_price) {
          totalNeutralVolumeChange += Math.abs(minuteData[i].public_total_volume - minuteData[i - 1].public_total_volume);
      } else if (minuteData[i].pub_last_traded_price > minuteData[i - 1].pub_last_traded_price) {
          totalBullVolumeChange += Math.abs(minuteData[i].public_total_volume - minuteData[i - 1].public_total_volume);
      }
      else if (minuteData[i].pub_last_traded_price < minuteData[i - 1].pub_last_traded_price) {
        totalBearVolumeChange += Math.abs(minuteData[i].public_total_volume - minuteData[i - 1].public_total_volume);
    }
  }
  const totalSum = totalBullVolumeChange + totalBearVolumeChange + totalNeutralVolumeChange;
  const bullPercentage = parseFloat((totalBullVolumeChange / totalSum * 100).toFixed(1));
  const bearPercentage =  parseFloat((totalBearVolumeChange / totalSum * 100).toFixed(1));
  const neutralPercentage =  parseFloat((totalNeutralVolumeChange / totalSum * 100).toFixed(1))

    const allTradeTimes = minuteData.map((data) => data.trade_time);
    const options = {
      chart: {
        type: "column",
        zoomType: "xy",
        height: 400,
        backgroundColor: "transparent",
      },
      title: {
        text: `Minute Chart - ${this.props.selectedInstrument}`,
        align: "left",
      },
      xAxis: {
        categories: allTradeTimes,
        crosshair: true,
      },
      yAxis: [
        {
          // Primary yAxis - Price
          labels: {
            format: "",
            style: {
              color: "blue",
            },
          },
          title: {
            text: "Price",
            style: {
              color: "blue",
            },
          },
        },
        {
          // Secondary yAxis - Volume
          title: {
            text: "Volume",
            style: {
              color: "black",
            },
          },
          labels: {
            format: "",
            style: {
              color: "black",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        align: "left",
        x: 80,
        verticalAlign: "top",
        y: 60,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor ||
          "rgba(255,255,255,0.25)",
      },
      series: [
        {
          name: "Volume",
          type: "column",
          yAxis: 1,
          data: volumeChanges.map((volumeChange, index) => ({
            y: volumeChange,
            color:
              index === 0 ||
              pubLastTradedPrices[index] === pubLastTradedPrices[index - 1]
                ? "blue"
                : pubLastTradedPrices[index] > pubLastTradedPrices[index - 1]
                ? "green"
                : "red",
          })),
          tooltip: {
            valueSuffix: "",
          },
        },
        {
          name: "Price",
          type: "spline",
          color: "red",
          yAxis: 0,
          data: minuteData.map((data) =>
            parseFloat(data.pub_last_traded_price)
          ),
          tooltip: {
            valueSuffix: "",
          },
        },
        {
          // Pie chart series
          type: "pie",
          name: "Total Percentage",
          enableMouseTracking: true,
          dataLabels: {
            format: "{point.y}",
          },
          animation: {
            duration: 2000,
          },
          tooltip: {
            formatter: function () {
              return '<b>' + this.point.name + '</b>: ' + this.y.toFixed(1) + '% (' + this.point.volume + ' units)';
            },
            valueSuffix: "%",
          },
          colorByPoint: true,
          data: [
            {
              name: `Total Bull Volume : ${totalBullVolumeChange}`,
              y: bullPercentage,
              // y: 255,
              color: "green",
            },
            {
              name: `Total Bear Volume : ${totalBearVolumeChange}`,
              y: bearPercentage,
              // y:200,
              color: "red",
            },
            {
              name: `Total Neutral Volume : ${totalNeutralVolumeChange}`,
              y: neutralPercentage,
              // y: 300,
              color: "#4d88ff",
            },
          ],
          center: [90, 70],
          size: 60,
          innerSize: "0%",
        },
        
      ],
    };

    // Function to calculate the total volume for a specific type (bull, bear, neutral)

    return (
      <Fragment>
        {isLoaded ? (
          minuteData.length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              allowChartUpdate={true}
              containerProps={{ className: "minuteChart" }}
              ref={this.chartRef}
            />
          ) : (
            <div>No data available</div>
          )
        ) : (
          <div>Loading...</div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.utils.setting,
});
const mapDispatchToProps = (dispatch) => ({
  minuteChartMatrix: (requestData) => dispatch(minuteChartMatrix(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinuteChart);
