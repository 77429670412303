import React, { Component, Fragment, PureComponent } from "react";
import Marquee from "react-fast-marquee";
import { connect } from "react-redux";
import { setLayout, setPath, setPathLayout } from "../../actions/Utils";
import { base_path } from "../../config/Api";

import IndexMover from "./IndexMover";
import IntradayDataHightchart from "../../components/chart/IntradayDataHightchart";
import TradingStatistics from "../../components/chart/tradingstatitics";
import TradeValue from "../../components/chart/tradevalue/index";
import SectorUpDown from "../../components/chart/sectorupdown/SectorUpDown";
import SectorTotalValue from "../../components/chart/sectortotalvalue/SectorTotalValue";
import StrengthMeter from "../chart/strengthmeter/index";
import { withRouter } from "react-router-dom";
import TopTrade from "./TopTrade";
import MarketInfo from "../chart/marketInfo";
import MarketMap from "../chart/marketMap";

class ChartView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      market: "",
    };
  }
  componentDidMount = () => {
    //console.log("hello ticker");
    /* $('.js-conveyor-3').jConveyorTicker({
            reverse_elm: true
          }); */
  };

  static getDerivedStateFromProps(props, state) {
    return {
      market: props.market,
    };
  }

  routeChange = (slug = "/") => {
    let path = slug;
    this.props.history.push(path);
  };

  render() {
    const { market } = this.state;
    console.log("chartview=", market);
    return (
      <Fragment>
        {/* <div className='row'>
                    <div className='col-md-6 mb-3' ><IntradayDataHightchart market={market} /></div>
                    <div className='col-md-6 mb-3' ><StrengthMeter market={market} /></div>
                    <div className='col-md-6 mb-3' ><TradingStatistics market={market} /></div>
                    <div className='col-md-6 mb-3' ><TopTrade market={market} /></div>
                </div> */}
        <div className="row">
          <div className="col-md-4 mb-3">
            <MarketInfo market={market} />
          </div>
          <div className="col-md-4 mb-3">
            <IntradayDataHightchart market={market} />
          </div>
          <div className="col-md-4 mb-3">
            <StrengthMeter market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <TradingStatistics market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <IndexMover market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <TopTrade market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <SectorTotalValue market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <SectorUpDown market={market} />
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <TradeValue market={market} />
          </div>
          <div className="col-lg-12 col-md-12 mb-3">
            <MarketMap />
          </div>
          
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  market: state.utils.setting.market,
});

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartView)
);
