import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { stockLoserApi } from '../../../actions/Utils';
import { base_path } from '../../../config/Api';

class StocksLosers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stock_loser: [],
            market: {},
        }

    }

    componentDidMount = () => {
        setTimeout(() => {
         this.handleGetStockLosers();
        }, 2150);
     }
 
     handleGetStockLosers = () =>{
        console.log("Yes handleGetTrendingTicker");
         var requestData = {
             date:this.state.market?this.state.market.open_date:''
         }
         this.props.stockLoserApi(requestData).then((res) => {
             if ((Object.keys(res).length) && res.status == 'success') {
                 this.setState({
                    stock_loser: res.data
                 })
             }
         });
     }

     static getDerivedStateFromProps(props, state) {

        return {
            market: props.market,
            stock_loser: props.stock_loser,
        }
    }






    render() {
        const lang = this.props.lang;
        // console.log("pathname =",pathname);
        const {stock_loser} = this.state;

        return (
            <Fragment>
               
                <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="table-wrap">
                            <p className='title'>Top Losers</p>
                            <div className='table-box'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Symbol</th>
                                                <th>Last Price</th>
                                                <th>Change</th>
                                                <th>%Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {stock_loser.map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.instrument && <Link to={{pathname:`${row.instrument?'/symbol/'+row.instrument.name:''}`}} className="rightsite-link">{row.instrument.name}</Link>}</td>
                                                    <td>{row.last_traded_price}</td>
                                                    <td className="text-danger">{(Math.fround(row.rchange * 100) / 100).toFixed(2)}</td>
                                                    <td className="text-danger">{(Math.fround(row.pchange * 100) / 100).toFixed(2)} %</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                            </div>
                      </div>
                    </div>
                   
                </div>
                
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    market: state.utils.setting.market,
    stock_loser: state.utils.stock_loser,
})

const mapDispatchToProps = (dispatch) => ({
    stockLoserApi: (requestData) => dispatch(stockLoserApi(requestData)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StocksLosers));

