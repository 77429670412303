import React, { PureComponent } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { StrengthMeterData, setPathLayout } from "../../../actions/Utils";

class StrengthMeter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      puller_count: 0,
      dragger_count: 0,
      unchanged_count: 0,
      is_load: false,
    };
  }

  componentDidMount() {
    this.handleStrengthMeter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleStrengthMeter();
    }
  }

  handleStrengthMeter = () => {
    const { market } = this.props.setting;
    const { is_load } = this.state;
    let requestData = {
      tdate: market ? market.open_date : "",
    };

    this.setState({ puller_count: 0, dragger_count: 0, unchanged_count: 0 });

    if (market && is_load === false) {
      this.props
        .StrengthMeterData(requestData)
        .then((res) => {
          this.setState({
            puller_count: res.data.puller_count,
            dragger_count: res.data.dragger_count,
            unchanged_count: res.data.unchanged_count,
            is_load: true,
          });
        })
        .catch((error) => {
          console.log("Error fetching Data:", error);
        });
    }
  };

  render() {
    const { puller_count, dragger_count, unchanged_count } = this.state;
    console.log("strength meter", this.state);
    const data = [
      ["Gainers", puller_count],
      ["Losers", dragger_count],
      ["Unchanged", unchanged_count],
    ];

    const colors = data.map((item) => {
      const label = item[0];
      if (label === "Gainers") {
        return "#347deb";
      } else if (label === "Losers") {
        return "#b81818";
      } else if (label === "Unchanged") {
        return "#d1cbcb";
      }
    });

    const options = {
      chart: {
        height: "330px",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "DSEX Strength",
        align: "center",
        verticalAlign: "middle",
        y: 60,
        style: {
          fontSize: "1.1em",
        },
      },
      tooltip: {
        pointFormat: "<b>{point.y} {point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
              fontSize: "16px",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
          colors: colors,
          allowPointSelect: true,
          cursor: "pointer",
          showInLegend: true,
        },
      },
      series: [
        {
          type: "pie",
          innerSize: "55%",
          data: data,
        },
      ],
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.utils.lang,
  setting: state.utils.setting,
});

const mapDispatchToProps = (dispatch) => ({
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  StrengthMeterData: (requestData) => dispatch(StrengthMeterData(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrengthMeter);
