import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { indexSummeryInfo } from "../../../actions/HomeLayout";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import TradeValue from "../tradevalue/TradeValue";
import {
  setLayout,
  setPath,
  setPathLayout,
  setSearchKey,
  getSetting,
} from "../../../actions/Utils";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      market_summery: {
        collections: [],
        weeklyAnalysis: {
          percentageChange: 0, // Example default value
        },
        monthlyAnalysis: {
          percentageChange: 0, // Example default value
        },
        yearlyAnalysis: {
          percentageChange: 0, // Example default value
        },
      },
      has_error: false,
      activeTab: 10001,
    };
    this.chartRef = React.createRef();
    this.indexSummeryInterval = null; // Initialize interval ID
  }

  componentDidMount() {
    if (!this.props.setting) {
      // Fetch the settings first if not already loaded
      this.props.getSetting().then(() => {
        if (this.props.setting && this.props.setting.market) {
          this.handleIndexSummery("10001");
        }
      });
    } else if (this.props.setting && this.props.setting.market) {
      this.handleIndexSummery("10001");
    }

    // Set up interval for updating the market summary
    this.indexSummeryInterval = setInterval(() => {
      this.handleIndexSummery(this.state.activeTab); // Use active tab for the instrument ID
    }, 5000); // Update every 3 seconds
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleIndexSummery(this.state.activeTab);
    }
  }

  componentWillUnmount() {
    clearInterval(this.indexSummeryInterval); // Clear the interval when unmounting
  }

  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting,
    };
  }

  handleIndexSummery = (instrument_id) => {
    const { market } = this.props.setting;
    if (market && instrument_id) {
      console.log("Requesting index summary with:", {
        instrument_id,
        market_id: market.market_id,
      });
      this.props
        .indexSummeryInfo({
          tdate: market.open_date || "",
          instrument_id,
          market_id: market.market_id || "",
        })
        .then((res) => {
          console.log("Market Summary Response:", res);
          if (res && res.status === "success") {
            this.setState({
              market_summery: res.data,
              has_error: false,
            });
          } else {
            this.setState({
              has_error: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching market summary:", error);
          this.setState({
            has_error: true,
          });
        });
    }
  };

  chartCallback = () => {
    console.log("Hello chartCallback");
  };

  handleChangeIndex = (index_val) => {
    console.log("index_val =", index_val);
    if (index_val) {
      this.setState({
        isLoaded: false,
        activeTab: index_val,
      });

      // Fetch market summary data for the selected instrument
      this.handleIndexSummery(index_val);
    }
  };

  render() {
    const { market_summery, activeTab } = this.state;
    const { collections, weeklyAnalysis, monthlyAnalysis, yearlyAnalysis } =
      market_summery;

    console.log("Market Summery collections:", collections);
    console.log("Market Summery weeklyAnalysis:", weeklyAnalysis);
    console.log("Market Summery monthlyAnalysis:", monthlyAnalysis);
    console.log("Market Summery yearlyAnalysis:", yearlyAnalysis);

    const activeCollection = collections.length > 0 ? collections[0] : null;

    const getProgressBarWidth = (percentage) => {
      return `${Math.min(Math.max(percentage, 0), 100)}%`;
    };

    const getPercentageChangeClass = (percentage) => {
      return percentage < 0 ? "text-danger" : "text-success";
    };

    return (
      <Fragment>
        <div className="box">
          <div className="box-header">Index Summery</div>
          <div className="box-body" style={{ height: "395px" }}>
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <ul className="nav nav-tabs" style={{ width: "100%" }}>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10001 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10001)}
                  >
                    DSEX
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10002 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10002)}
                  >
                    DS30
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10003 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10003)}
                  >
                    DSES
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="dse"
                role="tabpanel"
                aria-labelledby="dse-tab"
              >
                <div
                  className="dse-tab-content mt-4"
                  style={{ margin: "18px" }}
                >
                  {activeCollection && (
                    <div className="row">
                      <div className="col-6 text-center">
                        <span className="large-bold">
                          {activeCollection.dayClose}
                        </span>
                        <p className="label">{activeCollection.Date}</p>
                      </div>
                      <div className="col-6 text-center">
                        <span className="large-bold">
                          {activeCollection?.dayDeviation !== null &&
                          activeCollection?.dayDeviation !== undefined
                            ? activeCollection.dayDeviation
                            : "0.00"}
                          (
                          {activeCollection?.dayPdeviation !== null &&
                          activeCollection?.dayPdeviation !== undefined
                            ? activeCollection.dayPdeviation
                            : "0.00"}
                          %)
                        </span>
                        <p className="label">Movement</p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="row">
                      <div className="col-6 text-right">
                        <span
                          className={getPercentageChangeClass(
                            weeklyAnalysis.percentageChange
                          )}
                        >
                          Weekly:{weeklyAnalysis.percentageChange}%
                        </span>
                      </div>
                      <div className="col-6">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{
                              width: getProgressBarWidth(
                                weeklyAnalysis.percentageChange
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        <span
                          className={getPercentageChangeClass(
                            monthlyAnalysis.percentageChange
                          )}
                        >
                          6 Month:{monthlyAnalysis.percentageChange}%
                        </span>
                      </div>
                      <div className="col-6">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{
                              width: getProgressBarWidth(
                                monthlyAnalysis.percentageChange
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        <span
                          className={getPercentageChangeClass(
                            yearlyAnalysis.percentageChange
                          )}
                        >
                          Yearly:{yearlyAnalysis.percentageChange}%
                        </span>
                      </div>
                      <div className="col-6">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{
                              width: getProgressBarWidth(
                                yearlyAnalysis.percentageChange
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeCollection && (
                    <div>
                      <div className="row">
                        <div className="col-4 text-center">
                          <span className="large-bold">
                            {activeCollection.dayOpen}
                          </span>
                          <p className="label">Day Open</p>
                        </div>
                        <div className="col-4 text-center">
                          <span className="large-bold">
                            {activeCollection.dayHigh}
                          </span>
                          <p className="label">Day High</p>
                        </div>
                        <div className="col-4 text-center">
                          <span className="large-bold">
                            {activeCollection.dayLow}
                          </span>
                          <p className="label">Day Low</p>
                        </div>
                      </div>
                      {activeCollection &&
                        activeTab === 10001 && ( // Only render for DSEX tab
                          <div className="row">
                            <div className="col-4 text-center">
                              <span className="large-bold">
                                {activeCollection.totalTrade}
                              </span>
                              <p className="label">Total Trade</p>
                            </div>
                            <div className="col-4 text-center">
                              <span className="large-bold">
                                {activeCollection.totalVolume}
                              </span>
                              <p className="label">Total Volume</p>
                            </div>
                            <div className="col-4 text-center">
                              <span className="large-bold">
                                {activeCollection.totalValue}
                              </span>
                              <p className="label">Total value (cr)</p>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  setting: state.utils.setting,
});
const mapDispatchToProps = (dispatch) => ({
  indexSummeryInfo: (requestData) => dispatch(indexSummeryInfo(requestData)),
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
