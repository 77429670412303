import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { recentlyView } from '../../../actions/Utils';
import { base_path } from '../../../config/Api';


class RecentlyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recent_view: [],
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.handleGetRecentView();
        }, 700);
    }

    handleGetRecentView = () => {
        var symbol_list = Cookies.get('recently_view');
        const { market } = this.props.setting;
        if (market) {
            var requestData = {
                symbol: symbol_list ? symbol_list : '',
                date: market ? market.open_date : ''
            }
            this.props.recentlyView(requestData).then((res) => {
                if ((Object.keys(res).length) && res.status == 'success') {
                    this.setState({
                        recent_view: res.data
                    })
                }
            });
        }
    }


    static getDerivedStateFromProps(props, state) {

        return {
            recent_view: props.recent_view,
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.setting !== this.props.setting) {
            this.handleGetRecentView(this.props.search_slug);
        }
    }



    render() {
        const lang = this.props.lang;
        // console.log("pathname =",pathname);
        const { recent_view } = this.state;

        return (
            <Fragment>
                <div className="row mt-2 mb-4">
                    <div className="col-md-12">
                        <div className="table-wrap recently-viewed-table box">
                            <p className='title box-header'>Recently Viewed</p>
                            <div className='table-box'>
                                {recent_view.length ? <table>
                                    <thead>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>Last Price</th>
                                            <th>Change</th>
                                            <th>%Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recent_view.map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.instrument && <Link to={{ pathname: `${row.instrument ? '/symbol/' + row.instrument.name : ''}` }} className="rightsite-link">{row.instrument.name}</Link>}</td>
                                                    <td>{row.last_traded_price}</td>
                                                    <td className={`${row.open_price > row.last_traded_price ? 'text-success' : 'text-danger'}`}>{(Math.fround(row.rchange * 100) / 100).toFixed(2)}</td>
                                                    <td className={`${row.open_price > row.last_traded_price ? 'text-success' : 'text-danger'}`}>{(Math.fround(row.pchange * 100) / 100).toFixed(2)}%</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table> : <div className='watchlist-box'>
                                    <p>Your list is empty.</p>

                                </div>}
                            </div>
                        </div>
                    </div>

                </div>

            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    recent_view: state.utils.recent_view,
    lang: state.utils.lang,
    search_slug: state.utils.search_slug,
})

const mapDispatchToProps = (dispatch) => ({
    recentlyView: (requestData) => dispatch(recentlyView(requestData)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentlyView));

