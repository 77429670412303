import {
	makeApiRequest,
	generateSymbol,
	parseFullSymbol,
} from './helpers.js';
// import {
// 	subscribeOnStream,
// 	unsubscribeFromStream,
// } from './streaming.js';

const lastBarsCache = new Map();

// DatafeedConfiguration implementation
const configurationData = {
	// Represents the resolutions for bars supported by your datafeed
	allow_symbol_change: true,
	studies: [
		"STD;Divergence%1Indicator"
	  ],
	supported_resolutions: ["3m","5m","15m","30m","D", "2D", "3D", "1W", "3W", "1M", "6M"],
	
	// The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
	exchanges: [{
		value: 'DSE',
		name: 'DSE',
		desc: 'DSE',
	}
	],
	// The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
	symbols_types: [{
		name: 'Stock',
		value: 'stock',
	},
	],
	supports_marks:true,
	charts_storage_url:'https://portal.shebacapital.com/',
};

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols(userInput = null) {
	// const data = await makeApiRequest('data/v3/all/exchanges');

	const urlParameters = {
		query: userInput,
	};
	const query = Object.keys(urlParameters)
		.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
		.join('&');

	const data = await makeApiRequest(`search?${query}`);
	let allSymbols = [];
	for (const exchange of configurationData.exchanges) {
		const pairs = data;
			const symbols = pairs.map(rightPairPart => {
				const symbol = generateSymbol(exchange.value, exchange.value, rightPairPart.symbol);
				console.log("symbol from generate",symbol);
				return {
					symbol: symbol.short,
					full_name: rightPairPart.symbol,
					description: rightPairPart.description,
					exchange: exchange.value,
					type: 'Stock',
				};
			});
			allSymbols = [...allSymbols, ...symbols];
		
	}
	allSymbols = [...allSymbols];
	return allSymbols;
}

export default {
	onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols(userInput);
		
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.description
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			const isSymbolContainsInput = symbol.symbol
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && (isFullSymbolContainsInput || isSymbolContainsInput);
		});
		console.log('[searchSymbols]: Method call',newSymbols);
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
		extension
	) => {
		console.log('[resolveSymbol]: Method call', symbolName);
		const symbols = await getAllSymbols(symbolName);
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		// Symbol information object
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			session: '24x7',
			timezone: 'Asia/Almaty',
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 1000,
			has_intraday: false,
			// has_no_volume : false,
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
			// visible_plots_set:'ohlcv',
			
		};

		console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		//const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		const urlParameters = {
			symbol: symbolInfo.ticker,
			to: to,
			from: from,
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');
		try {
			const data = await makeApiRequest(`history?${query}`);
			// console.log("getbars =",data);
			if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
				// "noData" should be set if there is no data in the requested period

				if(data.nextTime === false) {
					onHistoryCallback([], {
						noData: true,
					});
				}
				else {
					onHistoryCallback([], {
						noData: false,
					});
				}
				
				return;
			}
			let bars = [];
			data.Data.forEach(bar => {
				// console.log("getbars foreach =",bar)
				if (bar.time >= from && bar.time < to) {
					let dateString = bar.tdate+"T12:01:00";
					let vtime = Date.parse(dateString);
					bars = [...bars, {
						time: vtime,
						low: bar.low,
						high: bar.high,
						open: bar.open,
						close: bar.close,
						volume: bar.volumefrom,
					}];
				}
			});
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		// console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
		// subscribeOnStream(
		// 	symbolInfo,
		// 	resolution,
		// 	onRealtimeCallback,
		// 	subscriberUID,
		// 	onResetCacheNeededCallback,
		// 	lastBarsCache.get(symbolInfo.full_name),
		// );
	},

	unsubscribeBars: (subscriberUID) => {
		// console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		// unsubscribeFromStream(subscriberUID);
	},
};
