// Makes requests to CryptoCompare API
import axiosInstance, { ApiBaseURL,  version  } from '../../../config/Api'

export async function makeApiRequest(path) {

	try {
	
		var response = await axiosInstance.get(version + `${path}`);
		return response.data;

	} catch (error) {
		throw new Error(`request error: ${error}`);
	}

	// try {
	// 	const response = await fetch(`${ApiBaseURL}${version}${path}`);
	// 	// console.log("make api =",response.json());
	// 	return response.json();
	// } catch (error) {
	// 	throw new Error(`CryptoCompare request error: ${error.status}`);
	// }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${toSymbol}`;
	return {
		short,
		full: `${short}`,
	};
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
