import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { stockGainerApi } from '../../../actions/Utils';
import { base_path } from '../../../config/Api';

class StocksGainers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stock_gainer: [],
            market: {},
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.handleGetStockGainers();
             console.log('Market data:', this.props.market);
        }, 500);
    }

    handleGetStockGainers = () => {

        const { market } = this.props.setting;
        var requestData = {
            date: market ? market.open_date : ''
        }

        if (market) {
            this.props.stockGainerApi(requestData).then((res) => {
                if ((Object.keys(res).length) && res.status == 'success') {
                    this.setState({
                        stock_gainer: res.data
                    })
                }
            });
        }

    }

    static getDerivedStateFromProps(props, state) {

        return {
            market: props.market,
            stock_gainer: props.stock_gainer,
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.setting !== this.props.setting) {
            this.handleGetStockGainers();
        }
    }
    render() {
        const lang = this.props.lang;
        // console.log("pathname =",pathname);
        const { stock_gainer } = this.state;
        return (
            <Fragment>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="table-wrap">
                            <p className='title'>Top Gainers</p>
                            <div className='table-box'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>Last Price</th>
                                            <th>Change</th>
                                            <th>%Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stock_gainer.map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.instrument && <Link to={{ pathname: `${row.instrument ? '/symbol/' + row.instrument.name : ''}` }} className="rightsite-link">{row.instrument.name}</Link>}</td>
                                                    <td>{row.last_traded_price}</td>
                                                    <td className="text-success">{(Math.fround(row.rchange * 100) / 100).toFixed(2)}</td>
                                                    <td className="text-success">{(Math.fround(row.pchange * 100) / 100).toFixed(2)} %</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    market: state.utils.setting.market,
    stock_gainer: state.utils.stock_gainer,
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    stockGainerApi: (requestData) => dispatch(stockGainerApi(requestData)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StocksGainers));

