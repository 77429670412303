import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import {
  setPath,
  setLayout,
  setPathLayout,
  indexChart,
  prevIndexChart,
} from "../../actions/Utils";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Tabs, { Tab } from "react-best-tabs";
import MomentTimeZone from "moment-timezone";
import Moment from "moment";

MomentTimeZone();
Moment();

window.moment = Moment;

Highcharts.setOptions({
  time: {
    timezone: "Asia/Dhaka",
  },
});

class IntradayDataHighchart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataPoints: [],
      isLoaded: false,
      instrument_id: 10001,
      last_id: "",
      tdate: "",
      data_load: {
        "5D": false,
        "1M": false,
        "6M": false,
        YTD: false,
        "1Y": false,
        "5Y": false,
      },
      activeTab: 10001, // Initialize the active tab with default value
    };
    this.chartRef = React.createRef();
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }

  componentDidMount() {
    console.log("Intraday Data Highchart =", this.props);
    this.handleIndexChart();
    this.indexChartID = setInterval(() => this.handleUpdateIndexChart(), 10000);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      market: props.market,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleIndexChart();
    }
  }

  componentWillUnmount() {
    clearInterval(this.indexChartID);
  }

  handleIndexChart = () => {
    const { market } = this.props.setting;
    let requestData = {
      instrument_id: this.state.instrument_id,
      tdate: market ? market.open_date : "",
    };

    if (market) {
      this.props.indexChart(requestData).then((res) => {
        var dps = [];
        if (res.length) {
          for (var i = 0; i < res.length; i++) {
            dps.push([res[i].unix_date_time, res[i].capital_value]);
          }
          this.setState({
            isLoaded: true,
            dataPoints: [...dps],
            last_id: res[res.length - 1]["id"],
          });
        }
      });
    }
  };

  handleUpdateIndexChart = () => {
    const { market } = this.props.setting;
    let requestData = {
      instrument_id: this.state.instrument_id,
      last_id: this.state.last_id,
      tdate: market ? market.open_date : "",
    };

    if (market) {
      this.props.indexChart(requestData).then((res) => {
        if (res.length) {
          var dps = [];
          for (var i = 0; i < res.length; i++) {
            dps.push([res[i].unix_date_time, res[i].capital_value]);
          }
          this.setState({
            isLoaded: true,
            dataPoints: [...this.state.dataPoints, ...dps],
            last_id: res[res.length - 1]["id"],
          });
        }
      });
    }
  };

  handleDateRange = (number, value) => {
    // Handle date range update
  };

  handleUpdateIndexChartDateRange = (number, value) => {
    const { market } = this.props.setting;
    let requestData = {
      instrument_id: this.state.instrument_id,
      last_id: this.state.last_id,
      tdate: market ? market.open_date : "",
      range_number: number,
      range_cal: value,
    };

    if (market) {
      this.props.prevIndexChart(requestData).then((res) => {
        if (res.length) {
          var dps = [];
          for (var i = 0; i < res.length; i++) {
            dps.push([res[i].unix_date_time, res[i].capital_value]);
          }
          this.setState({
            isLoaded: true,
            dataPoints: [...this.state.dataPoints, ...dps],
          });
        }
      });
    }
  };

  handleChangeIndex = (index_val) => {
    console.log("index_val =", index_val);
    if (index_val) {
      this.setState({
        isLoaded: false,
        instrument_id: index_val,
        dataPoints: [],
        last_id: "",
        activeTab: index_val,
      });

      setTimeout(() => {
        this.handleIndexChart();
      }, 1000);
    }
  };

  render() {
    const { isLoaded, activeTab } = this.state;

    const options = {
      chart: {
        height: 350,
        backgroundColor: "transparent",
      },
      time: {
        timezone: "Asia/Dhaka",
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return Highcharts.dateFormat("%I %M", this.value);
          },
        },
      },
      legend: {
        align: "left",
      },
      rangeSelector: {
        allButtonsEnabled: false,
        buttons: [],
        selected: 8,
        inputEnabled: false,
      },
      navigator: {
        enabled: false,  // Disable the navigator
      },
      series: [
        {
          name: "",
          type: "area",
          data: this.state.dataPoints,
          tooltip: {
            pointFormat: '<p style="color:black">{point.y:,.2f}%</p>',
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#34b1eb"],
              [1, "#34b1eb"],
            ],
          },
          lineColor: "#34b1eb",
          threshold: null,
        },
      ],
    };

    return (
      <Fragment>
        <div className="index-chart box">
          <div className="box-header"> Index Chart </div>
          <div className="box-body" style={{ height: "395px" }}>
            {/* <Tabs
              activeTab={this.state.activeTab}
              className="tradingstatistics"
              ulClassName=""
              activityClassName="bg-success"
            >
              <Tab
                title="DSEX"
                className=""
                onClick={() => this.handleChangeIndex(10001)}
              >
                <button
                  className={`nav-link ${activeTab === 10001 ? "active" : ""}`}
                >
                </button>
              </Tab>
              <Tab
                title="DS30"
                className=""
                onClick={() => this.handleChangeIndex(10002)}
              >
                <button
                  className={`nav-link ${activeTab === 10002 ? "active" : ""}`}
                >
                </button>
              </Tab>
              <Tab
                title="DSES"
                className=""
                onClick={() => this.handleChangeIndex(10003)}
              >
                <button
                  className={`nav-link ${activeTab === 10003 ? "active" : ""}`}
                >
                </button>
              </Tab>
            </Tabs> */}

            <div className="option-area">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10001 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10001)}
                  >
                    DSEX
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10002 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10002)}
                  >
                    DS30
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === 10003 ? "active" : ""
                    }`}
                    onClick={() => this.handleChangeIndex(10003)}
                  >
                    DSES
                  </button>
                </li>
              </ul>
            </div>
            <Fragment>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
                allowChartUpdate={true}
                containerProps={{ className: "chartContainer" }}
                ref={this.chartRef}
              />
            </Fragment>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.utils.setting,
});
const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  indexChart: (requestData) => dispatch(indexChart(requestData)),
  prevIndexChart: (requestData) => dispatch(prevIndexChart(requestData)),
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntradayDataHighchart);