
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { indexMoverPullers, indexMoverDraggers, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import Tabs, { Tab } from 'react-best-tabs';
import { Link } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

class IndexMover extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      pullers: [],
      pullers_load: false,
      draggers: [],
      draggers_load: false
    }
  }

  componentDidMount = () => {

    this.handlePullers();
    // const { market } = this.props.setting;

    // let requestData = {
    //   tdate: market ? market.open_date : ''
    // };

    // this.props.indexMoverPullers(requestData).then((res) => {
    //   this.setState({
    //     pullers: [...res],
    //     pullers_load: true
    //   });
    // });

  }

  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting
    }
  }

  componentDidUpdate(prevProps, state) {
    if (prevProps.setting !== this.props.setting) {

      this.handlePullers();
    }
    else {
      console.log("index mover state=", state);
    }
  }

  handleTabLoad = (event, tab) => {
    const { pullers_load, draggers_load } = this.state;
    if (tab == 2 && !draggers_load) {
      this.handleDraggers();
    }
    if (tab == 1 && !pullers_load) {
      this.handlePullers();
    }

  }

  handleDraggers = () => {

    const { market } = this.props.setting;

    let requestData = {
      tdate: market ? market.open_date : ''
    };

    if (market) {
      this.props.indexMoverDraggers(requestData).then((res) => {
        this.setState({
          draggers: [...res],
          draggers_load: true
        });
      });
    }
  }


  handlePullers = () => {

    const { market } = this.props.setting;
    let requestData = {
      tdate: market ? market.open_date : ''
    };

    if (market) {
      this.props.indexMoverPullers(requestData).then((res) => {
        this.setState({
          pullers: [...res],
          pullers_load: true
        });
      });
    }

  }


  render() {


    console.log("movers state", this.state);
    const { pullers, pullers_load, draggers, draggers_load } = this.state;

    return (
      <section className="index_mover">

        <div className="index_mover-tabs box">
          <div className='box-header'> Index Mover </div>
          <div className='box-body'>
            <Tabs activeTab="1" className="" ulClassName="pro-tab-items" activityClassName="bg-success pro-tab-active" onClick={(event, tab) => this.handleTabLoad(event, tab)}>

              <Tab title="Gainers" className="service-tab-nav" key={'tab1'}>

                <div className='scrolling-add table-responsive' style={{ height: '340px' }}>
                  <table className='table table-striped table-borderless'>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>LTP</th>
                        <th>YCP</th>
                        <th>IND CHG</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pullers_load && pullers.length > 0 && pullers.map((row, key) => {
                        return <Fragment key={key}>
                          <tr>
                            {/* <td>{row.instrument ? row.instrument.name : ''}</td> */}
                            <td>{row.instrument.name && <Link to={{ pathname: `${row.instrument.name ? '/symbol/' + row.instrument.name : ''}` }} className="rightsite-link">{row.instrument.name}</Link>}</td>
                            <td>{parseFloat(row.last_traded_price).toFixed(2)}</td>
                            <td>{parseFloat(row.yday_close_price).toFixed(2)}</td>
                            <td>{parseFloat(row.pchange).toFixed(2)}</td>
                          </tr>
                        </Fragment>
                      })}

                      {!pullers_load && <tr>
                        <td colSpan={4}><ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} /></td>
                      </tr>}
                    </tbody>
                  </table>
                </div>

              </Tab>
              <Tab title="Losers" className="service-tab-nav" key={'tab2'}>
                <div className='scrolling-add table-responsive' style={{ height: '340px' }}>
                  <table className='table table-striped table-borderless'>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>LTP</th>
                        <th>YCP</th>
                        <th>IND CHG</th>
                      </tr>
                    </thead>
                    <tbody>

                      {draggers_load && draggers.length > 0 && draggers.map((row, key) => {
                        return <Fragment key={key}>
                          <tr>
                            <td>{row.instrument.name && <Link to={{ pathname: `${row.instrument.name ? '/symbol/' + row.instrument.name : ''}` }} className="rightsite-link">{row.instrument.name}</Link>}</td>
                            <td>{parseFloat(row.last_traded_price).toFixed(2)}</td>
                            <td>{parseFloat(row.yday_close_price).toFixed(2)}</td>
                            <td className='text-danger'>{parseFloat(row.pchange).toFixed(2)}</td>
                          </tr>
                        </Fragment>
                      })}

                      {!draggers_load && <tr>
                        <td colSpan={4}><ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} /></td>
                      </tr>}

                    </tbody>
                  </table>
                </div>
              </Tab>

            </Tabs>
          </div>
        </div>

      </section >
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.utils.lang,
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  indexMoverPullers: (requestData) => dispatch(indexMoverPullers(requestData)),
  indexMoverDraggers: (requestData) => dispatch(indexMoverDraggers(requestData))
})

export default connect(mapStateToProps, mapDispatchToProps)(IndexMover);