import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout, todayTradStatistics } from '../../../actions/Utils'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



class TodaysCharts extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dataPoints: [],
            todaychart: {
                up: 0,
                down: 0,
                flat: 0,
                loading: false
            },
            isLoaded: false,
            instrument_id: 10001,
            ctime: new Date(),
            content: {
                puller_count: 0,
                dragger_count: 0,
                unchanged_count: 0,
              }
        };
        this.chartRef = React.createRef();
    }

    // componentDidMount() {

    //     this.handleTodayTradeStatistics();
    //     this.indexChartID = setInterval(
    //         () => this.handleUpdateTodayTradestatistics(),
    //         10000
    //     );
    // }

    static getDerivedStateFromProps(props, state) {
        return {
          content: { ...props.strength_meter },
          setting: props.setting
        }
      }

    componentDidUpdate(prevProps) {

        // if (prevProps.setting !== this.props.setting) {
        //     this.handleTodayTradeStatistics();
        // }
    }

    componentWillUnmount() {
        clearInterval(this.indexChartID);
    }

    handleTodayTradeStatistics = () => {
        const { market } = this.props.setting;

        let requestData = {
            tdate: market ? market.open_date : ''
        };

        if (market) {
            this.props.todayTradStatistics(requestData).then((res) => {

                this.setState({
                    isLoaded: true,
                    todaychart: { ...res, loading: true }
                });
            });
        }
    }

    handleUpdateTodayTradestatistics = () => {
        const { market } = this.props.setting;

        let requestData = {
            tdate: market ? market.open_date : ''
        };

        if (market) {
            this.props.todayTradStatistics(requestData).then((res) => {
                this.setState({
                    todaychart: { ...res }
                });
            });
        }
    }




    chartCallback = () => {
        console.log("Hello chartCallback");
    }

    render() {

        const { content } = this.state;
        // console.log("todaychart js =",this.state);

        const options = {
            chart: {
                type: 'column',
                height: 330,
                backgroundColor: 'transparent',
            },
            title: {
                align: 'left',
                text: ''
            },
            subtitle: {
                align: 'left',
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px;color:black">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <p style="color:black"> <b>{point.y:f}</b> of total</p><br/>'
            },

            series: [
                {
                    name: "DSEX",
                    colorByPoint: true,

                    data: [
                        {
                            name: "UP",
                            y: parseInt(content.puller_count),
                            drilldown: "UP",
                            color: '#34b1eb'
                        },
                        {
                            name: "FLAT",
                            y: parseInt(content.unchanged_count),
                            drilldown: "FLAT",
                            color: '#ffbb33'
                        },
                        {
                            name: "DOWN",
                            y: parseInt(content.dragger_count),
                            drilldown: "DOWN",
                            color: '#ff4444'
                        }
                    ]
                }
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'right'
                    }
                },
                series: [
                    {
                        name: "UP",
                        id: "UP",
                        data: [
                            [
                                "v1.0",
                                parseInt(content.puller_count)
                            ]

                        ]
                    },
                    {
                        name: "FLAT",
                        id: "FLAT",
                        data: [
                            [
                                "v2.0",
                                parseInt(content.unchanged_count)
                            ]

                        ]
                    },
                    {
                        name: "DOWN",
                        id: "DOWN",
                        data: [
                            [
                                "v3.0",
                                parseInt(content.dragger_count)
                            ]

                        ]
                    }

                ]
            }

        }

        return (
                <Fragment>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={options}
                        allowChartUpdate={true}
                        containerProps={{ className: 'todayschart' }}
                        ref={this.chartRef}
                    />
                </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    setting: state.utils.setting,
    strength_meter: state.utils.strength_meter,
})
const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    todayTradStatistics: (requestData) => dispatch(todayTradStatistics(requestData)),
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) }
})



export default connect(
    mapStateToProps, mapDispatchToProps
)(TodaysCharts);