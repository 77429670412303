import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  setPath,
  setLayout,
  setPathLayout,
  minuteChartMatrix,
} from "../../../actions/Utils";
import MinuteCharts from "./MinuteChart";
import RightSide from "../../common/rightsite";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        [
          { instrument: "", key: Math.random().toString(36).substring(7) },
          { instrument: "", key: Math.random().toString(36).substring(7) },
        ],
      ],
      instrumentCodes: [],
      selectedInstruments: [],
    };
  }

  componentDidMount() {
    this.handleminuteChartMatrix();
  }

  static getDerivedStateFromProps(props, state) {
    
    return {
      setting: props.setting
    }
  }

  handleminuteChartMatrix = (selectedInstrument) => {
    const { instrument,market } = this.props.setting;
    if (!instrument) {
      console.error("No instrument selected.");
      return;
    }
    const instrumentCodes =  instrument;
    let requestData = {
      q: selectedInstrument,
      trade_batch: market ? market.trade_batch : '',
      // tdate:market ? market.open_date : ''
    };
    this.props
      .minuteChartMatrix(requestData)
      .then((res) => {
        if (res) {
          this.setState(
            {
              isLoaded: true,
              instrumentCodes: instrumentCodes, // Set instrumentName from the response
            },
            () => {
              console.log("instrumentCodes:", this.state.instrumentCodes);
            }
          );
        } else {
          console.error("Invalid response data structure:", res);
          // Handle invalid response structure
          // For example, show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error fetching minute chart data:", error);
        // Handle error
        // For example, show an error message to the user
      });
  };

  handleSelectChange = (event, rowIndex, columnIndex) => {
    const { value } = event.target;
    const { selectedInstruments } = this.state;
    selectedInstruments[rowIndex] = selectedInstruments[rowIndex] || {}; // Ensure the selectedInstrument object exists for the current row
    selectedInstruments[rowIndex][columnIndex] = value; // Update the selected instrument in the array
    this.setState(
      {
        selectedInstruments,
      },
      () => {
        // After setting the selected instrument, call handleminuteChartMatrix with the selected instrument
        const selectedInstrument = value;
        this.handleminuteChartMatrix(selectedInstrument, rowIndex, columnIndex);
      }
    );
  };

  handleAddRow = () => {
    this.setState((prevState) => ({
      rows: [
        ...prevState.rows,
        [
          { instrument: "", key: Math.random().toString(36).substring(7) },
          { instrument: "", key: Math.random().toString(36).substring(7) },
        ],
      ],
    }));
  };

  render() {
    const { rows, selectedInstruments } = this.state;
    const instrumentCodes =this.props.setting.instrument;

    return (
      <Fragment>
        <div className="container" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-lg-9">
              <div className="row mt-2 mb-3">
                <div className="col-md-12">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={this.handleAddRow}
                  >
                    More Minute Chart
                  </button>
                </div>
              </div>
              {rows.map((row, rowIndex) => (
                <div className="row" key={rowIndex}>
                  {row.map((select, columnIndex) => (
                    <div className="col-lg-6" key={select.key}>
                      <select
                        className="form-control"
                        value={
                          selectedInstruments[rowIndex]?.[columnIndex] || ""
                        }
                        onChange={(event) =>
                          this.handleSelectChange(event, rowIndex, columnIndex)
                        }
                      >
                        <option value="">Select Instrument</option>
                        {instrumentCodes &&
                          Object.entries(instrumentCodes).map(
                            ([code, name]) => (
                              <option key={code} value={name}>
                                {name}
                              </option>
                            )
                          )}
                      </select>
                      <div className="mt-2">
                        {selectedInstruments[rowIndex]?.[columnIndex] && (
                          <MinuteCharts
                            selectedInstrument={
                              selectedInstruments[rowIndex][columnIndex]
                            }
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="col-lg-3">
              <div className="rightsite">
                <RightSide />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.utils.setting,
});
const mapDispatchToProps = (dispatch) => ({
  minuteChartMatrix: (requestData) => dispatch(minuteChartMatrix(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
