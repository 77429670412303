import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setLayout, setPath, setPathLayout, setSearchKey, getSetting, Search } from '../../../actions/Utils';
import { base_path } from '../../../config/Api';



class SearchHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: {},
      search_key: '',
      is_loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.refs = React.createRef();
  }

  componentDidMount = () => {

  }


  handleMenu = (status) => {
    this.setState({
      menu_open: status
    })
  }


  handleChange = event => {
    this.searchkey.value = '';
    this.setState({
      suggestions: {},
      search_key: ''
    })

  };

  handleSubmit = event => {
    event.preventDefault();
    const { history, match } = this.props;
    const { search } = this.state;
    if (search.length) {
      this.setState({ search: '' });
      history.push(`${process.env.PUBLIC_URL}${match.url}search/${(search)}`);
      $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
    }
  };


  onChange = (e) => {
    const search_key = e.target.value;
    let suggestions = [];
    let requestData = {
      q: search_key
    }
    if (search_key.length > 0) {
      this.props.Search(requestData).then((res) => {
        if (res.status == 'success') {
          this.setState({
            suggestions: res.data,
            search_key: search_key
          })
        }

      });
    }
    else {
      this.setState({
        suggestions: {},
        search_key: ''
      })
    }


  };

  suggestionsSelect(value) {
    this.setState(() => ({
      text: value,
      suggestions: [],
    }));
  }




  render() {


    const { search_key, suggestions, is_loading } = this.state;
    // const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
    const lang = this.props.lang;

    // console.log("pathname =",pathname);
    console.log("search suggestions =", suggestions);


    return (
        <div className='row mb-4'>
        <div className="row mb-4">
        <div className="right_side_search-container">
          <div className="right_side_search-inner">
            <input
              type="text"
              className="me-1"
              onKeyUp={this.onChange}
              style={{ width: 380, height: 38 }}
              placeholder="&nbsp; &nbsp;Search For symbols"
              ref={(input) => this.searchkey = input}
            />
            <button
              className="btn btn-primary borderradious0"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
          {search_key &&<div className="right_side_dropdown right_side_modules_noDisplay__Qnbur">
            <div className="">
            {suggestions.symbols && suggestions.symbols.length ? <Fragment>
              <div className="right_side_modules_sectionHeader">
                <ul>
                  {suggestions.symbols.map((row,key) => {
                    return (
                      <li><Link to={{
                         pathname: "/symbol/"+ row.instrument_code
                        }}
                        className="right_side_dropdown-row  right_side_modules_linkItem__P-S-4"
                        key={key}
                        onClick={this.handleChange}
                      >
                        <div className='right_side_search_modules-row'>
                        <div className='col-9 mb-2'>
                        {row.instrument_code}
                        </div>
                        </div>     
                      </Link></li>
                    )
                  })}
                </ul>
                </div>
              </Fragment>:''}
            </div>
          </div>}
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  lang: state.utils.lang,
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
  setSearchKey: (requestData) => { dispatch(setSearchKey(requestData)) },
  getSetting: (requestData) => dispatch(getSetting(requestData)),
  Search: (requestData) => dispatch(Search(requestData)),

})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchHeader));