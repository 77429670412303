import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { newsCategoryName, categoryNewsByID } from "../../actions/HomeLayout";
import "react-best-tabs/dist/index.css";
import { image_url } from "../../config/Api";
import DatetimeAgo from "../common/DatetimeAgo";
import { ScaleLoader } from "react-spinners";

class CategoryNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category_name: [],
      active_tab: "",
      category_content: [],
      is_load: false,
    };
  }

  componentDidMount() {
    this.props.newsCategoryName({}).then((res) => {
      if (res.status === "success") {
        const new_category = res.data.map((row) => ({
          id: row.id,
          name: row.name,
          loading: false,
          content: [],
        }));
        this.setState({
          category_name: res.data,
          category_content: new_category,
          is_load: true,
        });
        if (res.data.length > 0) {
          this.handleGetCategory(res.data[0].id);
        }
      }
    });
  }

  handleGetCategory = (id) => {
    const requestData = { id };
    this.props.categoryNewsByID(requestData).then((res) => {
      if (res.status === "success") {
        const updatedCategoryContent = this.state.category_content.map((row) =>
          row.id === id ? { ...row, loading: true, content: res.data } : row
        );
        this.setState({
          active_tab: id,
          category_content: updatedCategoryContent,
        });
      }
    });
  };

  handleViewMore = (categoryId) => {
    const { category_name } = this.props;
    const categoryName = category_name.find((category) => category.id === categoryId)?.name;
    console.log("Category Name:", categoryName);
  };

  render() {
    const { category_name, category_content, is_load, active_tab } = this.state;

    return (
      <Fragment>
        <div className="row mt-5">
          <div className="col-md-12">
            {category_name.length > 0 ? (
              <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {category_name.map((row, key) => (
                    <li className="nav-item" key={key}>
                      <button
                        className={`nav-link news-custom-link ${active_tab === row.id ? "active" : ""}`}
                        onClick={() => this.handleGetCategory(row.id)}
                      >
                        {row.name}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content" id="myTabContent">
                  {!is_load && (
                    <div>
                      <ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} />
                    </div>
                  )}
                  {category_content.map((row, key) => (
                    <div
                      className={`tab-pane fade ${active_tab === row.id ? "show active" : ""}`}
                      id={`data${key}`}
                      role="tabpanel"
                      key={key}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mt-3">
                            <div className="news-category">
                              {row.loading ? (
                                <Fragment>
                                  {row.content.length > 0 ? (
                                    row.content.map((category_row, crow) => (
                                      <Fragment key={crow}>
                                        <div className="news-row" key={crow}>
                                          <div className="image">
                                            {category_row.file ? (
                                              <img
                                                src={`${image_url}uploads/news/thumbs/${category_row.file}`}
                                                alt="image"
                                                className="img-responsive"
                                                height={"132px"}
                                                width={"282px"}
                                              />
                                            ) : (
                                              <img
                                                src={`${image_url}uploads/no-image/no-image.png`}
                                                alt="image"
                                                className="img-responsive"
                                              />
                                            )}
                                          </div>
                                          <div className="news-description">
                                            <div className="news-category">
                                              <p>
                                                <span className="catgegory">
                                                  {category_row.category_name}
                                                </span>
                                                <i className="fa fa-chevron-double-right"></i>
                                                <span className="source">
                                                  {category_row.source}
                                                </span>
                                                <i className="fa fa-chevron-double-right"></i>
                                                <span className="datetime">
                                                  <DatetimeAgo
                                                    date={category_row.published_date}
                                                    locale="en-US"
                                                    timeStyle="twitter"
                                                  />
                                                </span>
                                              </p>
                                            </div>
                                            <div className="news-title">
                                              <h5>
                                                {category_row.title && (
                                                  <Link
                                                    to={{
                                                      pathname: category_row.slug
                                                        ? `/portal_news/${category_row.slug}`
                                                        : "",
                                                    }}
                                                    className="news-link"
                                                    style={{ textDecoration: "none" }}
                                                  >
                                                    {category_row.title}
                                                  </Link>
                                                )}
                                              </h5>
                                            </div>
                                            <div className="news-details">
                                              <p>{category_row.short_description}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    ))
                                  ) : (
                                    <div className="no-data">No Record Found</div>
                                  )}
                                </Fragment>
                              ) : (
                                <ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} />
                              )}
                            </div>
                          </div>
                        </div>
                        {row.loading && (
                          <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-end mb-2">
                              <Link
                                to={{ pathname: `/news/${row.name}` }}
                                className="btn btn-outline-primary font-bold view-more-news"
                                style={{ fontSize: 16 }}
                                onClick={() => this.handleViewMore(row.id)}
                              >
                                View More
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  category_name: state.contents.news_category_name,
});

const mapDispatchToProps = (dispatch) => ({
  newsCategoryName: (requestData) => dispatch(newsCategoryName(requestData)),
  categoryNewsByID: (requestData) => dispatch(categoryNewsByID(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryNews);
