import { timers } from "jquery";
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  setPath,
  setLayout,
  setPathLayout,
  indexChart,
} from "../../../actions/Utils";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";

import StrengthMeter from "./StrengthMeter";
import GainersLoosers from "./GainersLoosers";
import TradeValue from "../tradevalue/TradeValue";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    //  this.handleIndexChart();
    //  this.indexChartID = setInterval(
    //     () => this.handleUpdateIndexChart(),
    //     50000
    //   );
  }

  // componentWillUnmount() {
  //   //clearInterval(this.indexChartID);
  // }

  chartCallback = () => {
    console.log("Hello chartCallback");
  };

  render() {
    // console.log("State data=",this.state.dataPoints);

    const { isLoaded } = this.state;

    return (
      <Fragment>
        <div className="box">
          <div className="box-header">Strength Meter</div>
          <div className="box-body" style={{ height: "395px" }}>
            <Tabs
              activeTab="1"
              className="strength-meter"
              ulClassName=""
              activityClassName="bg-success"
              onClick={(event, tab) => console.log(event, tab)}
            >
              <Tab title="GAINER LOSERS PIE" className="">
                <div className="mt-3">
                  <GainersLoosers />
                </div>
              </Tab>
              <Tab title="STRENGTH METER" className="">
                <div className="mt-4" style={{ margin: "18px" }}>
                  <StrengthMeter />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  indexChart: (requestData) => dispatch(indexChart(requestData)),
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
