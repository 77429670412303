import Cookies from 'js-cookie';
const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
const utilsReducer = {
    path: '/',
    layout_id: '',
    routemenu: [],
    pubroutemenu: [],
    setting: {},
    header_loading: true,
    footer_loading: true,
    breadcrumb: [],
    member_token:'',
    meta_data:{},
    lang:lang,  
    recent_view:[],  
    trending_tickers:[],  
    stock_gainer:[],  
    stock_loser:[],
    search_slug:'',
    block_transaction:'',
    block_transaction_date:'',
    strength_meter: {}
}

export default (state = utilsReducer, action) => {
    switch (action.type) {
        case 'SET_PATH':
            return {
                ...state,
                path: action.path
            }
        case 'SET_LAYOUT_ID':
            return {
                ...state,
                layout_id: action.id
            }
        case 'SET_PATH_LAYOUT':

            return {
                ...state,
                path: action.data.path,
                layout_id: action.data.id,
            }
        case 'INIT_ROUTES':
            return {
                ...state,
                routemenu: action.data
            }
        case 'SET_HEADER_LOADING':
            return {
                ...state,
                header_loading: action.status
            }
        case 'SET_FOOTER_LOADING':
            
            return {
                ...state,
                footer_loading: action.status
            }
        case 'SET_SETTING':
            // console.log("Hello Mobarak=",action.data);
           
            return {
                ...state,
                setting: action.data
            }

             
        case 'SET_MARKET_BASIC':
            console.log("Hello Mobarak=",action.data);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    market:action.data
                }
            }
        case 'SET_MARKET_SCHEDULE':
            console.log("Hello Mobarak=",action.data);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    schedule:action.data
                }
            }
        case 'SET_INSTRUMENT':
            console.log("Hello Mobarak=",action.data);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    instrument:action.data
                }
            }
        case 'INIT_PUBLICATION_ROUTES':
            return {
                ...state,
                pubroutemenu: action.data
            }
        case 'SET_MEMBER_TOKEN':
            return {
                ...state,
                member_token: action.data
            }
        case 'INIT_META_DATA':
            return {
                ...state,
                meta_data: action.data
            }
        case 'SET_LANG':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                lang: action.data.lang
            }
        case 'INIT_RECENT_VIEW':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                recent_view: action.data
            }
        case 'INIT_TRENDING_TICKERS':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                trending_tickers: action.data
            }
        case 'INIT_STOCK_GAINER':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                stock_gainer: action.data
            }
        case 'INIT_STOCK_LOSER':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                stock_loser: action.data
            }
        case 'SET_SEARCH_SLUG':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                search_slug: action.slug
            }
        case 'INIT_BLOCK_TRANSACTION':
            // console.log("SET_LANG",action.data);
            return {
                ...state,
                block_transaction: action.data.data,
                block_transaction_date: action.data.rdate,
            }
        case 'SET_LOGIN_STATUS':
            
            console.log("SET_LOGIN_STATUS",action.data);
            var setting  = {};
            if(Object.keys(state.setting)) {
                setting = {
                    ...state.setting,
                    is_login: action.data
                }
            }
            else {
                setting = {
                    is_login: action.data
                }
            }
            console.log("setting for set login =",setting);
            return {
                ...state,
                setting: setting
            }
        case 'INIT_STRENGTH_METER':
            console.log("data setStrengthMeter",action.data);
            return {
                ...state,
                strength_meter: {...action.data}
            }
        default:
            return state;

    }
}
