export const Numbernm = (numbervalue) =>{
    let val = parseFloat(numbervalue);
    return Number(val/1000000).toFixed(2);
}

export const currentTime = ()=> {
    console.log("Hello Mobarak")
         return <h2>It is {new Date().toLocaleTimeString()}.</h2>
}

export const Clock = (props) => {
    return (
       props.date.toLocaleTimeString()
    );
  }
  
export const compaireDateTime = (props) => {

    var open_time = props.open_time;
    var close_time = props.close_time;
    var market_date = props.open_date;
    var market_date_time_open = market_date +' '+open_time;
    var market_date_time_close = market_date +' '+close_time;


    const date1 = new Date(market_date_time_open);
    const date2 = new Date(market_date_time_close);
    const today = new Date();
    market_date = new Date(market_date);


    if(market_date.toDateString() != today.toDateString() ) {
        return <span className="">Closed </span>
    }
    else if ((date1.getTime() <= date2.getTime()) && (today.getTime() <= date2.getTime()) ) {
        return <span className="text-success">Open</span>
    }
    else {
        return <span className="">Closed</span>
    }
}

export const routeChange = (props,slug = '/') => {
    let path = slug;
    console.log("Hello Mobarak=",this.props);
    props.history.push(path);
}
export const externalLinkUrl = (link,target) => {
    window.location.href(link);
}
export const differenceHour = (dt1, dt2) => {
        // Convert string representations of dates to Date objects
        const date1 = new Date(dt1);
        const date2 = new Date(dt2);
      
        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(date2 - date1);
      
        // Calculate the difference in days, hours, minutes, and seconds
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
      
        return {
          days: daysDifference,
          hours: hoursDifference,
          minutes: minutesDifference,
          seconds: secondsDifference
        };
}