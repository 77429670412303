import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {trendingTickerApi } from '../../../actions/Utils';
import { base_path } from '../../../config/Api';

class TrendingTickers extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            trending_ticker: [],
            market: {},
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
         this.handleGetTrendingTicker();
        }, 2100);
     }
 
     handleGetTrendingTicker = () =>{
        console.log("Yes handleGetTrendingTicker");
         var requestData = {
             date:this.state.market?this.state.market.open_date:''
         }
         this.props.trendingTickerApi(requestData).then((res) => {
             if ((Object.keys(res).length) && res.status == 'success') {
                 this.setState({
                    trending_ticker: res.data
                 })
             }
         });
     }

     
    static getDerivedStateFromProps(props, state) {

        return {
            market: props.market,
            trending_ticker: props.trending_ticker,
        }
    }


    render() {
        const lang = this.props.lang;
        // console.log("pathname =",pathname);
        const {trending_ticker} = this.state;

        return (
            <Fragment>
               
                <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="table-wrap">
                            <p className='title'>Trending Tickers</p>
                            <div className='table-box'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Symbol</th>
                                                <th>Last Price</th>
                                                <th>Change</th>
                                                <th>%Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {trending_ticker.map((row,key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{row.instrument && <Link to={{pathname:`${row.instrument?'/symbol/'+row.instrument.name:''}`}} className="rightsite-link">{row.instrument.name}</Link>}</td>
                                                    <td>{row.last_traded_price}</td>
                                                    <td className={`${row.open_price > row.last_traded_price?'text-success':'text-danger'}`}>{(Math.fround(row.rchange * 100) / 100).toFixed(2)}</td>
                                                    <td className={`${row.open_price > row.last_traded_price?'text-success':'text-danger'}`}>{(Math.fround(row.pchange * 100) / 100).toFixed(2)} %</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                            </div>
                      </div>
                    </div>
                   
                </div>
                
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    market: state.utils.setting.market,
    trending_ticker: state.utils.trending_tickers,
})

const mapDispatchToProps = (dispatch) => ({
    trendingTickerApi: (requestData) => dispatch(trendingTickerApi(requestData)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrendingTickers));

