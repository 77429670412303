import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout, tenDaysTradeStatistics } from '../../../actions/Utils'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { format } from 'date-fns'
import { random } from 'lodash';
import { flash } from 'react-animations';


class TenDaysChart extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      instrument_id: 10001,
      last_id: '',
      data: []
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {

    this.handletenDaysTradeStatistics();
    this.indexChartID = setInterval(
      () => this.handleUpdateTenDaysTradeStatistics(),
      10000
    );
  }


  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.setting !== this.props.setting) {
      this.handletenDaysTradeStatistics();
    }
  }

  componentWillUnmount() {
    clearInterval(this.indexChartID);
  }

  handletenDaysTradeStatistics = () => {
    const { market } = this.props.setting;

    let requestData = {
      tdate: market ? market.open_date : ''
    };

    if (market) {
      this.props.tenDaysTradeStatistics(requestData).then((res) => {

        this.setState({
          isLoaded: true,
          data: [...res],
        });

      });
    }
  }

  handleUpdateTenDaysTradeStatistics = () => {
    const { market } = this.props.setting;

    let requestData = {
      tdate: market ? market.open_date : ''
    };

    if (market) {
      this.props.tenDaysTradeStatistics(requestData).then((res) => {
        this.setState({
          data: [...res]
        });
      });
    }
  }

  render() {
    const { data } = this.state;
    var category = [];
    var dsex = [];
    var turnover = [];

    data.map((row) => {
      category.push(format(new Date(row.trade_date), 'dd MMM'));
      // desx.push( { 
      //     y:Math.floor(Math.random() * 10),
      //     change:Math.floor(Math.random() * 10)

      // });
      // turnover.push({
      //   y:Math.floor(Math.random() * 10),
      //   change:Math.floor(Math.random() * 10)
      // });

      dsex.push({
        y: row.indexValue,
        change: row.pdeviation

      });
      turnover.push({
        y: row.totalValueMN,
        change: row.pdeviation
      });

    })



    // console.log("ten days chart=",this.state);
    const options = {
      chart: {
        zoomType: 'xy',
        height: 310,
        width:330,
        backgroundColor: 'transparent',
      },
      legend: { enabled: false },
      title: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: category
      },
      yAxis: [
        {
          // Primary yAxis (Turnover)
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[4]
              },
            },
            title: {
              text: 'DSEX',
              style: {
                color: Highcharts.getOptions().colors[0]
              },
            },
            tickInterval: 30
          },
          { // Secondary yAxis (DSEX)
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: 'TURNOVER',
              style: {
                color: Highcharts.getOptions().colors[3]
              },
            },
        opposite: true,
      }
    ],

      labels: {
        items: [{
          html: '',
          style: {
            left: '50px',
            top: '18px',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'black'
          }
        }]
      },
      //   tooltip: {
      //     headerFormat: '<table class="mobaraktable">',
      //     pointFormat: '<tr>\
      //         <td style="color:{series.color};padding:0">{series.name}: </td>\
      //         <td style="padding:0"><b>{point.y:.1f}</b></td>\
      //         </tr>\
      //         <tr><br/>\
      //         <td style="color:{series.color};padding:0">{series.name} Chg: </td>\
      //         <td style="padding:0"><b>{point.change:.1f}</b></td>\
      //         </tr>',
      //     footerFormat: '</table>',
      //     shared: true,
      //     useHTML: true
      // },
      series: [
        {
        type: 'column', 
        name: 'Turnover',
        data: turnover,
        yAxis: 1, 
        tooltip: {
          headerFormat: '<table>',
          pointFormat: '<tr>\
                <td style="color:{series.color};padding:20">{series.name}: </td>\
                <td style="padding:20;color:black;"><b>{point.y:.1f}</b>,</td></tr>\
                <tr>\
                <td style="color:{series.color};padding:20">DSEX Chg: </td>\
                <td style="padding:20;color:black;"><b>{point.change:.1f}%</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        }
       }, 
       {
        type: 'spline',
        name: 'DSEX',
         data: dsex,
        datachange: dsex,
         yAxis: 0,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white'
        },
        tooltip: {
          headerFormat: '<table>',
          pointFormat: '<tr>\
                <td style="color:{series.color};padding:20">{series.name}: </td>\
                <td style="padding:20;color:black;"><b>{point.y:.1f}</b>,</td></tr>\
                <tr>\
                <td style="color:{series.color};padding:20">DSEX Chg: </td>\
                <td style="padding:20;color:black;"><b>{point.change:.1f}%</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          crosshairs: true,
        } ,
      }
    ]
    }

    console.log("ten days charts options =",options)

    return (
      <Fragment>
        <Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            options={options}
            allowChartUpdate={true}
            containerProps={{ className: 'tendayschart' }}
            ref={this.chartRef}
          />
        </Fragment>

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})
const mapDispatchToProps = (dispatch) => ({
  tenDaysTradeStatistics: (requestData) => dispatch(tenDaysTradeStatistics(requestData))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(TenDaysChart);