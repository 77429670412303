import { timers } from 'jquery';
import React, { Fragment,Component } from 'react';
import { connect } from 'react-redux';
import {setPath,setLayout,setPathLayout,indexChart} from '../../../actions/Utils'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';

import TradeValue from './TradeValue';



class Index extends Component {

    constructor(props) {
      super(props);
      this.state = { 
        
        };
        this.chartRef = React.createRef();
    }
   
    componentDidMount() {

    //  this.handleIndexChart();
    //  this.indexChartID = setInterval(
    //     () => this.handleUpdateIndexChart(),
    //     50000
    //   );
    }

    // componentWillUnmount() {
    //   //clearInterval(this.indexChartID);
    // }


  
    chartCallback = () =>{
      console.log("Hello chartCallback");
    }
   
    render() {
    
    // console.log("State data=",this.state.dataPoints);

    const {isLoaded} = this.state;
    
    


      return (
        <Fragment>
          <div className='box'>
          <div className='box-header'> Trade Value </div>
            <div className='box-body' style={{height:'520px'}}>
                  <TradeValue />
            </div>
          </div>
        </Fragment>
      );
    }
  }




const mapStateToProps = (state) =>({

})
const mapDispatchToProps = (dispatch) =>({
    
    indexChart : (requestData)=>dispatch(indexChart(requestData)),
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))}
})



export default connect(
    mapStateToProps,mapDispatchToProps
)(Index);