import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLayout, setPath, setPathLayout } from "../../actions/Utils";
import { allNews } from "../../actions/HomeLayout";
import { image_url } from "../../config/Api";
import RightSide from "../common/rightsite/index";
import DatetimeAgo from "../common/DatetimeAgo";
import { BeatLoader, ScaleLoader } from "react-spinners";

class PortalNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      page: 1,
      hasMore: true,
    };
  }
  componentDidMount() {
    let requestData = {
      page: this.state.page,
    };

    this.props
      .allNews(requestData)
      .then((res) => {
        console.log("res data", res);
        this.setState({
          items: res.data,
          page: this.state.page + 1,
        });
      })
      .catch((err) => {
        console.error("Error while fetching data", err);
      });
  }

  fetchMoreData = () => {
    const { items, page } = this.state;
    let requestData = {
      page: page,
    };

    if (!this.state.hasMore) {
      return;
    }

    this.props
      .allNews(requestData)
      .then((res) => {
        console.log("res data", res);
        const newItems = [...items, ...res.data];

        this.setState({
          items: newItems,
          page: page + 1,
          hasMore: res.data.length ? true : false,
        });
      })
      .catch((err) => {
        console.error("Error while fetching more data", err);
      });
  };
  render() {
    var section_content = this.state.items;
    var hasMore = this.state.hasMore;
    console.log("res section_content", this.state);
    return (
      <Fragment>
        <div className="container" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-lg-9">
              <div className="news-highlight">
                <InfiniteScroll
                  dataLength={section_content.length}
                  next={this.fetchMoreData}
                  hasMore={hasMore}
                  loader={<ScaleLoader color="#36d7b7" />}
                >
                  {section_content &&
                    section_content.map((row, key) => {
                      return (
                        <div className="news-row" key={key}>
                          <div className="image">
                            {row.file && (
                              <img
                                src={
                                  `${image_url}` +
                                  "uploads/news/thumbs/" +
                                  row.file
                                }
                                alt="image"
                                height={"132px"}
                                width={"282px"}
                              />
                            )}
                            {!row.file && (
                              <img
                                src={`${image_url}uploads/no-image/no-image.png`}
                                alt="image"
                              />
                            )}
                          </div>
                          <div className="news-description">
                            <div className="news-category">
                              <p>
                                <span className="catgegory">
                                  {row.category_name}
                                </span>
                                <span className="source">{row.source}</span>
                                <span className="datetime">
                                  <DatetimeAgo
                                    date={row.published_date}
                                    locale="en-US"
                                    timeStyle="twitter"
                                  />
                                </span>
                              </p>
                            </div>
                            <div className="title">
                              <h5>
                                {row.title && (
                                  <Link
                                    to={{
                                      pathname: `${
                                        row.slug
                                          ? "/portal_news/" + row.slug
                                          : ""
                                      }`,
                                    }}
                                    className="news-link"
                                    style={{ textDecoration: "none" }}
                                    target="_blank" // Add this line to open link in a new tab
                                  >
                                    {row.title}
                                  </Link>
                                )}
                              </h5>
                            </div>
                            <div className="news-details">
                              <p>{row.short_description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>
            </div>
            {/* <div className='col-md-3' >
              <div className='rightsite'>
                <RightSide />
              </div>
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  items: state.contents.items,
  setting: state.utils.setting,
});

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  allNews: (requestData) => dispatch(allNews(requestData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PortalNews);
