import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout,TradeValueChart } from '../../../actions/Utils'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { differenceHour } from '../../CommonFun';
import Moment from 'moment';



class TradeValue extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            response: {
                data: {},
                loading: false
            },
            isLoaded: false,
            ctime: new Date(),
            call_request_data: 0
        };
        this.chartRef = React.createRef();
    }

    componentDidMount() {

        this.handleTradeValue();
    //    this.TradeValue();
    //     this.indexChartID = setInterval(
    //         () => this.handleUpdateTradeValue(),
    //         10000
    //     );

        
    }

    static getDerivedStateFromProps(props, state) {
        return {
            setting: props.setting
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.setting !== this.props.setting) {
            this.handleTradeValue();
        }
    }

    componentWillUnmount() {
        clearInterval(this.indexChartID);
    }

    handleTradeValue = () => {
    const { market } = this.props.setting;
    let requestData = {
      tdate: market ? market.open_date : ''
    };

        this.props.TradeValueChart(requestData).then((res) => {
            this.setState({
                isLoaded: true,
                response: {
                    data: res,
                    loading:true
                }
            });
        });
    }

    handleUpdateTradeValue = () => {
        const { market } = this.props.setting;

    let requestData = {
      tdate: market ? market.open_date : ''
    };

        this.props.TradeValueChart(requestData).then((res) => {

            this.setState({
                response: {
                    data: res,
                    loading:true
                }
                
            });
        });
    }

    getProjected = (today) => {

        const { schedule } = this.props.setting;
        // console.log("this.props.setting =", schedule);
        
        if(schedule && schedule.market_schedule) {
            if(schedule.market_schedule) {
                const {market_schedule} = schedule;
                const open_date = market_schedule.open_date+'T'+market_schedule.open_time;
                const current_date = new Date(); // current date and time
                const difference = differenceHour(open_date, current_date);
                // console.log("difference =",difference);
                const total_minutes = 260; 
                if(difference.days >0) {
                    return 0;
                }
                var market_open_minutes = 0;
                if(difference.hours) {
                    market_open_minutes += difference.hours * 60;
                }
                if(difference.minutes) {
                    market_open_minutes += difference.minutes;
                }
                let existed_minutes = total_minutes - market_open_minutes;

                if(existed_minutes > total_minutes) {
                    return 0;
                }
                //let projected_value = (today * existed_minutes) / market_open_minutes;
                let projected_value = Math.fround((today * existed_minutes) / market_open_minutes).toFixed(2);
                return Number(projected_value);

            }
        }
        else {
            return 0;
        }
        
    }



    render() {
        const { response } = this.state;
        const data  = response.data;
        const { market } = this.props.setting;
        console.log("Trade value js =",data);
        var category = ['Today\'s', 'Yesterday', 'Weekly AVG'];
        var todayValue = 0;
        var yesterdayValue = 0;
        var weeklyValue = 0;
        var projectedValue = 0;
        
        if (data && Object.keys(data).length) {
            todayValue = data.today.capital_value ? data.today.capital_value:0;
            yesterdayValue = data.yesterday.capital_value ? data.yesterday.capital_value:0;
            weeklyValue = data.weeklyAverage.weekly_average ? Number(Math.fround(data.weeklyAverage.weekly_average).toFixed(2)):0;
        
            projectedValue = this.getProjected(todayValue);

        }
        var seriesdata = [{
            name: 'Real',
            color: '#347deb',
            data: [todayValue, yesterdayValue, weeklyValue]
        }, {
            name: 'Projected',
            color: 'yellow',
            data: [projectedValue, 0, 0]
        }];
        // let cdata = new Date();
        // let currentdate = cdata.toISOString().split('T')[0]

        let cdata = market?market.open_date:'';
        let currentdate = market?Moment(cdata).format('DD-MM-YYYY'):'';

        const options = {
            chart: {
                type: 'bar',
                marginLeft: 80,
                height: 500,
                borderRadius: 10,
                backgroundColor: 'transparent',
              
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: category
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Date : ' + currentdate,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            legend: {
                reversed: false,
                align: "center",
                verticalAlign: "top",
                layout: "horizontal",
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
            },
            series: seriesdata,
            pointWidth: 20,
            tooltip: {
                useHTML: true,
                className:'rhm-highchart-tooltip'
            },

        }

        //   console.log("options=",options)
        return (

            <Fragment>
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={options}
                    allowChartUpdate={true}
                    containerProps={{ className: 'tradevalue' }}
                    ref={this.chartRef}
                />
            </Fragment>

        );
    }
}




const mapStateToProps = (state) => ({
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    TradeValueChart: (requestData) => dispatch(TradeValueChart(requestData)),
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) }
})



export default connect(
    mapStateToProps, mapDispatchToProps
)(TradeValue);