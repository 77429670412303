import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setPath, setLayout, setPathLayout } from "../../../actions/Utils";
import RightSide from "../../common/rightsite";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import treemap from "highcharts/modules/treemap";
import { marketMapApi } from "../../../actions/HomeLayout";
import { data } from "jquery";
treemap(Highcharts);

class TreeMapChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {},
      market_map: [],
      has_error: false,
    };
  }

  // componentDidMount() {
  //   this.processData();
  // }
  componentDidMount() {
    this.handleMarketMap(); // Call the API to fetch the data when the component mounts
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log("section_content props", props);
  //   return {
  //     market_map: props.market_map,
  //   };
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleMarketMap();
    }
  }

  handleMarketMap = () => {
    const { market } = this.props.setting;
    if (market) {
      const requestData = {
        // tdate: "2022-09-01",
        tdate: market ? market.open_date : "",
      };

      this.props.marketMapApi(requestData).then((res) => {
        console.log("Market Map api =", res);
        if (res && res.status === "success") {
          this.setState(
            {
              market_map: res.data,
              has_error: false,
            },
            () => {
              this.processData();
            }
          );
        } else {
          this.setState({
            has_error: true,
          });
        }
      })
        .catch((error) => {
          console.error("API call failed:", error);
          this.setState({
            has_error: true,
          });
        });
    }

  };
  processData() {
    const { market_map } = this.state;
  
    const points = [];
    market_map.forEach((sector, regionI) => {
      const AveragePChange = parseFloat(sector.average_pchange).toFixed(2) + "%";
      const color = sector.average_pchange < 0 ? "rgb(128, 0, 0)" : "green";
  
      const regionP = {
        id: "id_" + regionI,
        name: `<span style='font-size: 19px;'>${sector.sector_name}</span> <br> <span style='font-size: 12px;'>(${AveragePChange})</span>`,
        color: color,
      };
  
      points.push(regionP);
  
      sector.instruments.forEach((instrument, countryI) => {
        const pchange = parseFloat(instrument.pchange).toFixed(2) + "%";
        const countryP = {
          id: regionP.id + "_" + countryI,
          name: `${instrument.name} (${pchange})`,
          parent: regionP.id,
          value: Math.abs(parseFloat(instrument.pchange)), // Use absolute value for the treemap
          color: instrument.pchange < 0 ? "rgb(128, 0, 0)" : "green", // Set color based on pchange
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "9px",
              textOutline: "none",
            },
            align: "center",
            verticalAlign: "middle",
            overflow: "justify",
          },
        };
        points.push(countryP);
      });
    });
  
    const chartOptions = {
      chart: {
        backgroundColor: null,
        height: 800,
      },
      series: [
        {
          name: "Dhaka Stock Exchange(DSE)",
          type: "treemap",
          layoutAlgorithm: "squarified",
          allowDrillToNode: true,
          animationLimit: 1000,
          dataLabels: {
            enabled: false,
          },
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
                verticalAlign: "top",
                align: "left",
                style: {
                  fontSize: "30px",
                  fontWeight: "bold",
                  textOutline: "white",
                },
                padding: 3,
              },
              borderWidth: 6,
            },
          ],
          data: points,
        },
      ],
      title: {
        text: "",
        align: "left",
      },
    };
  
    const containerStyle = {
      height: "100px",
    };
  
    this.setState({ chartOptions, containerStyle });
  }

  render() {
    return (
      <Fragment>
        <div className='box'>
          <div className='box-header'>Market Map</div>
          <div className='box-body' style={{ height: "auto" }}>
          <HighchartsReact
              highcharts={Highcharts}
              options={this.state.chartOptions}
            />
            {/* {this.state.has_error && <div>Error loading market data</div>} */}
          </div>
        </div>




        
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.utils.setting,
});
const mapDispatchToProps = (dispatch) => ({
  marketMapApi: (requestData) => dispatch(marketMapApi(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeMapChart);
