import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
// import '../App.css';
import HtmlHelmet from "../components/common/HtmlHelmet";
import HighlightNews from "../components/home/HighlightNews";
import ChartView from "../components/home/ChartView";
import RightSide from "../components/common/rightsite";
// import ErrorBoundary from '../components/common/ErrorBoundary';
// import BuggyCounter from '../components/common/BuggyCounter';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      header: {},
      container: {},
      footer: {},
      layout_id: "",
    };
  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const { location } = this.props.history;
    var { pathname } = location;
    // this.props.homeLayoutContent().then((res) => { })
  };

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  };
  getSetting = async () => {
    let data = await this.props.getSetting();
  };
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      home_layout: props.home_layout,
    };
  }
  getContentData = (menu_id) => {
    const requestData = {
      menu_id: menu_id,
    };
    this.props.sectionandContent(requestData);
  };

  render() {
    var { path } = this.state;
    const { header_loading, footer_loading } = this.props.utils;
    const { home_layout } = this.props;
    let str = path.substr(0, 1);
    if (str == "/") {
      path = path.substring(1);
    }

    console.log("home_layout=", home_layout);
    const content = home_layout.content;
    return (
      <Fragment>
        {this.props.meta_data ? <HtmlHelmet /> : ""}

        <div className="container" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-md-9">
              <ChartView />
              <HighlightNews />
            </div>
            <div className="col-md-3">
              <div className="rightsite">
                <RightSide />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  homelayout: state.homelayout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,
  meta_data: state.utils.meta_data,
  home_layout: state.contents.home_layout,
});

const mapDispatchToProps = (dispatch) => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
