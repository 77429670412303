import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
// import App from './PubApp';
import './index.css';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/Configurstore';

// import './i18n';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const store = configureStore();

if (process.env.NODE_ENV !== "development")
    console.log = () => { };

ReactDOM.hydrate(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={<div class="ripple"></div>}>
                <App />
            </Suspense>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// serviceWorkerRegistration.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//https://www.cluemediator.com/implement-multi-languages-in-react
//https://www.mindbowser.com/adding-multi-language-support-using-reactjs/