import { timers } from 'jquery';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { setPath, setLayout, setPathLayout, tenDaysTradeStatistics } from '../../../actions/Utils'
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { Numbernm } from '../../CommonFun';


class TenDaysList extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      instrument_id: 10001,
      last_id: '',
      data: []
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {

    this.handletenDaysTradeStatistics();
    //  this.indexChartID = setInterval(
    //     () => this.handleUpdateTenDaysTradeStatistics(),
    //     50000
    //   );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      setting: props.setting
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.setting !== this.props.setting) {
      this.handletenDaysTradeStatistics();
    }
  }


  componentWillUnmount() {
    clearInterval(this.indexChartID);
  }

  handletenDaysTradeStatistics = () => {

    const { market } = this.props.setting;
    let requestData = {
      tdate: market ? market.open_date : ''
    };

    this.props.tenDaysTradeStatistics(requestData).then((res) => {

      this.setState({
        isLoaded: true,
        data: [...res],
      });

    });
  }

  handleUpdateTenDaysTradeStatistics = () => {
    let requestData = {

    };

    this.props.tenDaysTradeStatistics(requestData).then((res) => {
      this.setState({
        data: [...res]
      });
    });
  }





  render() {


    const { isLoaded, data } = this.state;

    console.log("ten days list", data);


    return (
      <Fragment>
        <div className='table-responsive' style={{ height: '340px' }}>
          <table className='table table-striped table-borderless tablescroll'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Vol(mn)</th>
                <th>Value(mn)</th>
                <th>DSEX</th>
                <th>%CHG</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 && data.map((row, key) => {
                return (
                  <tr key={key}>
                    <td>{format(new Date(row.trade_date), 'dd MMM,yyyy')}</td>
                    <td>{Numbernm(row.volume)}</td>
                    <td>{Math.fround(row.totalValueMN).toFixed(2)}</td>
                    <td>{row.indexValue}</td>
                    <td>{Math.fround(row.pdeviation).toFixed(2)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </Fragment>
    );
  }
}




const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})
const mapDispatchToProps = (dispatch) => ({
  tenDaysTradeStatistics: (requestData) => dispatch(tenDaysTradeStatistics(requestData)),
})



export default connect(
  mapStateToProps, mapDispatchToProps
)(TenDaysList);