import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ShowBlockTransaction } from "../../../actions/Utils";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { size } from "lodash";
import { ScaleLoader } from "react-spinners";

class BlockTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      block_transaction: [],
      block_transaction_date: [],
      market: {},
      is_load:false
    };
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.handleGetShowBlockTransaction();
    }, 500);
  };

  handleGetShowBlockTransaction = async () => {
    const { market } = this.props.setting;
    const requestData = {
      date: market ? market.open_date : "",
    };
    if (market) {
      try {
        const res = await this.props.ShowBlockTransaction(requestData);
        if (res.status === "success") {
          //console.log("res block =",res);
          this.setState({
            block_transaction: res.data,
            block_transaction_date: res.rdate,
            is_load:true
          });
        } else {
          this.setState({
            error: res.message,
            is_load:true
          });
        }
      } catch (err) {
        this.setState({
          error: err.message,
          is_load:true
        });
      }
    }
  };

  static getDerivedStateFromProps(props, state) {
    return {
      market: props.market,
      block_transaction: props.block_transaction,
      block_transaction_date: props.block_transaction_date,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      this.handleGetShowBlockTransaction();
    }
  }

  render() {
    const { block_transaction, block_transaction_date, error,is_load } = this.state;


    if (error) {
      return <div>Error: {error}</div>;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="table-wrap block-transaction-table box">
              <div className="row">
                <div className="col-md-12">
                  <div className="title box-header">Block Transaction</div>
                </div>
              </div>
              {(block_transaction.length) ? (
                <div className="table-scroll table-box">
                  <table className="table-striped">
                    <thead>
                      <tr>
                        <th className="text-left">Symbol</th>
                        <th className="text-left">Qty</th>
                        <th className="text-left">Value</th>
                        <th className="text-left">Max.P</th>
                        {/* <th className="text-left">Min.P</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {block_transaction &&
                        block_transaction.map((row, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              {row.instruments_code && (
                                <Link
                                  to={{
                                    pathname: `${row.instruments_code
                                      ? "/symbol/" + row.instruments_code
                                      : ""
                                      }`,
                                  }}
                                  className="rightsite-link"
                                >
                                  {row.instruments_code}
                                </Link>
                              )}
                            </td>
                            <td className="text-left">{row.quantity}</td>
                            <td className="text-left">  {(Math.fround(row.value)).toFixed(
                              2
                            )}</td>
                            <td className="text-left">{(Math.fround(row.max_price)).toFixed(
                              2
                            )}</td>
                            {/* <td className="text-left">{(Math.fround(row.min_price)).toFixed(
                              2
                            )}</td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Fragment>
                  {(block_transaction.length == 0 && is_load) ?
                    <Fragment>
                      <div className="watchlist-box">
                          <p>You have no block transaction list</p>
                      </div>
                    </Fragment> :''
                    
                  }
                </Fragment>
              )}
              {(block_transaction.length > 0) ?
                <div className="row">
                  <div className="col-md-12 text-right" style={{ fontSize: 12 }}>
                    <div className="block-date">Date : {block_transaction_date}</div>
                    <Link
                      to={{ pathname: `/blocktransaction/list` }}
                      className="view_all"
                    >
                      View All
                    </Link>
                  </div>
                </div> : ''}

              {!is_load && block_transaction.length == 0 && <div className="watchlist-box">
                <td colSpan={4}><ScaleLoader color="#09913A" height="15px" cssOverride={{ marginLeft: 20 }} /></td>
              </div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.utils.setting,
  block_transaction: state.utils.block_transaction,
  block_transaction_date: state.utils.block_transaction_date,
  error: state.utils.error,
});

const mapDispatchToProps = (dispatch) => ({
  ShowBlockTransaction: (requestData) =>
    dispatch(ShowBlockTransaction(requestData)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlockTransaction)
);
